//import { NavLink } from "react-router-dom";
import {useProductContext} from "../context/productcontex";
import Product from "./Product";

const HomeFeaturedProd =()=>{

    const { isLoading, featureProducts } = useProductContext();
    //console.log(featureProducts);
    if (isLoading) {
        return <div> ......Loading </div>;
    }

    return (

    <div className="ltn__product-area ltn__product-gutter  no-product-ratting pt-20 pb-20">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title-area ltn__section-title-2 ">
                        {/* <h1 className="section-title">Featured Products</h1> */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="row ltn__tab-product-slider-one-active--- slick-arrow-1">

                        {featureProducts.slice(0, 8).map((curElem) => {
                            return <Product key={curElem.id}{...curElem} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>


    );

};
export default HomeFeaturedProd;