import { loadJS } from "./Helpers/GlobalFunctions";
import { useEffect, useState, useRef } from "react";
import { useCartContext } from "./context/cart_context";
import {useNavigate } from "react-router-dom";


const Login = () =>{
    const {checkLogin } = useCartContext();    
    const [error, setError] = useState([]);
    const ref = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {  
        loadJS("./js/plugins.js", false);
        loadJS("./js/main.js", false);
        
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [userInput, setUserInput] = useState({
        email: "",
        password: "",
        
    });
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const handleInput = (e) => {
        e.persist();
        setUserInput({...userInput, [e.target.name]: e.target.value });
    }
    async function SubmitLogin()
    {
        if(!userInput.email || userInput.email==='')
        {
            alert("Please enter email address.");
            emailRef.current.focus();
            return false;
        }
        else
        {
            let validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

            if (!validEmail.test(userInput.email) ) 
            {
                alert("Please enter valid email address.");
                emailRef.current.focus();
                return false;
            }
        }

        if(!userInput.password || userInput.password==='')
        {
            alert("Please enter password.");
            passwordRef.current.focus();
            return false;
        }
        const loginResponse = await checkLogin(userInput.email,userInput.password);
        console.log('loginrespoinse:',loginResponse);
        if(loginResponse.userid && loginResponse.userid!==null)
        { 
            navigate('/myaccount');
        }
        else
        {
            setError("Invalid Email address or password.");
        }
        return false;
    }

    useEffect(() =>  {
        if(ref.current )
        {
            setTimeout(async () =>  {
                await ref.current.click();  
            }, 10);
        }
    }, [ref]);

    return (
        <>
        <div className="ltn__checkout-area mb-120 text-left">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                            <h1 className="section-title">Sign In <br/>To  Your Account</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="account-login-inner">
                            <form action="#" className="ltn__form-box contact-form-box">
                                <small className="text-danger">{error}</small>
                                <input type="text" onChange={handleInput} value={userInput.email} ref={emailRef} name="email" placeholder="Email*" />
                                <small className="text-danger">{error.email}</small>
                                <input type="password" onChange={handleInput} value={userInput.password} ref={passwordRef} name="password" placeholder="Password*" />
                                <small className="text-danger">{error.password}</small>
                                <div className="btn-wrapper mt-0">
                                    <button className="theme-btn-1 btn btn-block" onClick={() => SubmitLogin()} type="button">SIGN IN</button>
                                </div>
                                <div className="go-to-btn mt-20">
                                    <a href="/forgot-password"><small>FORGOTTEN YOUR PASSWORD?</small></a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="account-create text-center pt-50">
                            <h4>DON'T HAVE AN ACCOUNT?</h4>
                            <p>Add items to your wishlistget personalised recommendations <br/>
                                check out more quickly track your orders register</p>
                            <div className="btn-wrapper">
                                <a href="register.html" className="theme-btn-1 btn black-btn">CREATE ACCOUNT</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </>
    );
}; 
export default Login;