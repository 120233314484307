import {React, useState} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useCartContext } from "../context/cart_context";
import CartItemSidebar from "./CartItemSidebar";
import FormatPrice from "../Helpers/FormatPrice";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useProductContext } from "../context/productcontex";


const Header = () => {

    const { cart, total_price, total_item, current_user} = useCartContext();  
    const location = useLocation();
    const [search, setSearch] = useState('');
    //alert(location.pathname);
    const { products } = useProductContext();
    const navigate = useNavigate();
    
        //console.log("filter_products:",products);
        //console.log("items:",items);
    
      const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        console.log("searching:"+string, results)
        setSearch(string);
      }
    
      const handleOnHover = (result) => {
        // the item hovered
        console.log(result)
      }
    
      const handleOnSelect = (item) => {
        // the item selected
        console.log(item);
        navigate('./singleproduct/'+item.slug);
        const locpath = location.pathname;
        if(locpath.includes('singleproduct')){
            window.location.reload();
        }
      }
    
      const handleOnFocus = () => {
        console.log('Focused')
      }
    
      const formatResult = (item) => {
        return (
          <>
            <span style={{ display: 'block', textAlign: 'left' }}><img src={item.image} alt={item.name} height="30" border="1" /> {item.name}</span>
          </>
        )
      }
      
 

  return (
    <>
    <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile--- ltn__header-logo-and-mobile-menu--- ltn__header-transparent gradient-color-4---">
       
        <div className="ltn__header-top-area border-bottom top-area-color-white---">
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <div className="ltn__top-bar-menu">
                            <ul>
                                <li><a href="mailto:info@rxkin.com"><i className="icon-mail"></i> info@rxkin.com</a></li>
                                <li><a href="locations.html"><i className="icon-placeholder"></i> Ram Bagan - Ramkirishna Colony, P.O. Sear Sol Rajbari, Rajniganj - Burdawan, W. Bengal</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="top-bar-right text-end">
                            <div className="ltn__top-bar-menu">
                                <ul>
                                    <li>
                                        
                                        <div className="ltn__social-media">
                                            <ul>
                                                <li><a href="/" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="/" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                                
                                                <li><a href="/" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                                                <li><a href="/" title="Dribbble"><i className="fab fa-dribbble"></i></a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div className="ltn__header-middle-area ltn__logo-right-menu-option ltn__header-row-bg-white ltn__header-padding ltn__header-sticky ltn__sticky-bg-white desktop ">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="site-logo-wrap">
                            <div className="site-logo">
                                <NavLink to='/'><img src="/images/logo.png" alt="Logo"/></NavLink>
                            </div>
                            <div className="get-support clearfix d-none">
                                <div className="get-support-icon">
                                    <i className="icon-call"></i>
                                </div>
                                <div className="get-support-info">
                                    <h6>Get Support</h6>
                                    <h4><a href="tel:+123456789">123-456-789-10</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col header-menu-column menu-color-white---">
                        <div className="header-menu d-none d-xl-block">
                            <nav>
                                <div className="ltn__main-menu">
                                    <ul>
                                        {/* <li className="menu-icon"><NavLink to='/products' >Brands</NavLink>
                                            <ul className="mega-menu">
                                                <li><a href="/">A</a>
                                                    <ul>
                                                        <li><NavLink to='/products' >A-Derma</NavLink></li>
                                                        <li><NavLink to='/products' >Abbott</NavLink></li>
                                                        <li><NavLink to='/products' >Aclaris Therapeutics</NavLink></li>
                                                        <li><NavLink to='/products' >Adonis</NavLink></li>
                                                        <li><NavLink to='/products' >Adroit Biomed</NavLink></li>
                                                        <li><NavLink to='/products' >Ajanta Pharma</NavLink></li>
                                                    </ul> 
                                                </li>
                                                <li><a href="/">B</a>
                                                    <ul>
                                                        <li><a href="history.html">BABE Laboratorios</a></li>
                                                        <li><a href="contact.html">Biocon Biologics</a></li>
                                                        <li><a href="locations.html">Bioderma</a></li>
                                                        <li><a href="404.html">Blistex</a></li>
                                                        <li><a href="contact.html">Brillare</a></li>
                                                        <li><a href="coming-soon.html">Brinton</a></li>
                                                    </ul>
                                                </li>
                                                <li><a href="/">C</a>
                                                    <ul>
                                                        <li><a href="shop.html">Cantabria Labs</a></li>
                                                        <li><a href="shop-left-sidebar.html">Capeli</a></li>
                                                        <li><a href="shop-right-sidebar.html">Catalysis S.L.</a></li>
                                                        <li><a href="shop-grid.html">CeraVe</a></li>
                                                        <li><a href="product-details.html">Cipla </a></li>
                                                        <li><a href="cart.html">Clinikally</a></li>
                                                    </ul>
                                                </li>
                                                <li><a href="/">D</a>
                                                    <ul>
                                                        <li><a href="shop.html">Dermaceutic</a></li>
                                                        <li><a href="shop-left-sidebar.html">Dermalogica</a></li>
                                                        <li><a href="shop-right-sidebar.html">DERMATICA</a></li>
                                                        <li><a href="shop-grid.html">Dr. Reddy's</a></li>
                                                        <li><a href="product-details.html">Ducray </a></li>
                                                        <li><a href="cart.html">Dermawiz Laboratories</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li> */}
                                        <li><NavLink to='/' >Home</NavLink></li>
                                        <li className="menu-icon"><NavLink to='/products' >Skin</NavLink>
                                            <ul>
                                                <li><NavLink to='/products' >Ageing</NavLink></li>
                                                <li><NavLink to='/products' >Acne & Acne Scars</NavLink></li>
                                                <li><NavLink to='/products' >Damaged & Sensitive Skin</NavLink></li>
                                                <li><NavLink to='/products' >Pigmentation</NavLink></li>
                                                <li><NavLink to='/products' >Under Eye Concerns</NavLink></li>
                                                <li><NavLink to='/products' >Stretch Marks</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-icon"><NavLink to='/products' >Hair</NavLink>
                                            <ul>
                                                <li><NavLink to='/products' >Dandruff</NavLink></li>
                                                <li><NavLink to='/products' >Hair Fall & Hair Loss</NavLink></li>
                                                <li><NavLink to='/products' >Damaged & Frizzy Hair</NavLink></li>
                                                <li><NavLink to='/products' >Itchy & Irritated Scalp</NavLink></li>
                                                <li><NavLink to='/products' >Bald Patches </NavLink></li>
                                            </ul>
                                        </li>
                                        
                                        <li><NavLink to='/products' >Shop All</NavLink></li>
                                        {/* <li className="menu-icon"><a href="/">More</a>
                                            <ul>
                                                <li><a href="blog.html">About Us</a></li>
                                                <li><a href="blog-grid.html">FAQs</a></li>
                                                <li><a href="blog-left-sidebar.html">Contact Us</a></li>
                                            </ul>
                                        </li> */}
                                        
                                        
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="col ltn__header-options d-lg-block mb-sm-20">
                        <div className="header-contact-search">
                            <div className="header-search-2">
                                <form id="#123" method="get"  action="/products">
                                    <input type="text" name="search" value={search} className="search_top mb-2" placeholder="Search here..."/>
                                    <ReactSearchAutocomplete
                                        items={products}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                        placeholder="Search Here.."
                                        showIcon false
                                        fuseOptions={{
                                            keys: ['name'],
                                            minMatchCharLength: 3   ,
                                          }}
                                          styling={{
                                            border: '1px',
                                            clearIconMargin: '0px 0px 0px 0px',
                                            iconColor: "#ffffff",
                                            hoverBackgroundColor: "#fff",
                                          }}
                                    />
                                    <button type="submit">
                                        <span><i className="icon-search"></i></span>
                                    </button>
                                </form>
                                

                            </div>
                        </div>
                    </div>
                    <div className="col--- ltn__header-options ltn__header-options-2 mb-sm-20">
                        <div className="ltn__drop-menu user-menu">
                            <ul>
                                <li>
                                    <a href onClick={(e) => {e.preventDefault(); }}><i className="icon-user"></i></a>
                                    <ul>
                                        {current_user.userName && current_user.userName!==''?(
                                        <><li><a href="/myaccount">My Account</a></li><li><a href="/logout">Logout</a></li></>):<><li><a href="/login">Sign in</a></li>
                                        <li><a href="/register">Register</a></li></>}
                                        
                                    </ul>
                                </li>
                            </ul>
                        </div>
                       
                        <div className="mini-cart-icon">
                            <a href="#ltn__utilize-cart-menu" className="ltn__utilize-toggle">
                                <i className="icon-shopping-cart"></i>
                                <sup>{total_item}</sup>
                            </a>
                        </div>
                        
                        <div className="mobile-menu-toggle d-xl-none">
                            <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
                                <svg viewBox="0 0 800 600">
                                    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                    <path d="M300,320 L540,320" id="middle"></path>
                                    <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div className="ltn__header-middle-area ltn__logo-right-menu-option ltn__header-row-bg-white ltn__header-padding ltn__header-sticky ltn__sticky-bg-white  mobile">
            <div className="container-fluid">
                <div className="row">
                    
                    <div className="col--- ltn__header-options ltn__header-options-2 mb-sm-5">
                        <div className="mobile-menu-toggle d-xl-none">
                            <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
                                <svg viewBox="0 0 800 600">
                                    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                    <path d="M300,320 L540,320" id="middle"></path>
                                    <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                                </svg>
                            </a>
                            <div className="site-logo-wrap">
                                <div className="site-logo">
                                    <NavLink to='/'><img src="/images/logo.png" alt="Logo"/></NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="mini-cart-icon">
                            <a href="#ltn__utilize-cart-menu" className="ltn__utilize-toggle">
                                <i className="icon-shopping-cart"></i>
                                <sup>{total_item}</sup>
                            </a>
                        </div>
                        {location.pathname!=='/'?(
                        <div className="header-search-wrap">
                            <div className="header-search-1">
                                <div className="search-icon">
                                    <i className="icon-search for-search-show"></i>
                                    <i className="icon-cancel  for-search-close"></i>
                                </div>
                            </div>
                            <div className="header-search-1-form">
                            <form id="#123" method="get"  action="/products">
                                    <input type="text" name="search" value={search} className="search_top mb-2" placeholder="Search here..."/>
                                    <ReactSearchAutocomplete
                                        items={products}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                        placeholder="Search Here.."
                                        showIcon false
                                        fuseOptions={{
                                            keys: ['name'],
                                            minMatchCharLength: 3   ,
                                          }}
                                          styling={{
                                            border: '1px',
                                            clearIconMargin: '0px 0px 0px 0px',
                                            iconColor: "#ffffff",
                                            hoverBackgroundColor: "#fff",
                                          }}
                                    />
                                    <button type="submit">
                                        <span><i className="icon-search"></i></span>
                                    </button>
                                </form>
                            </div>
                        </div>):''}
                        <div className="ltn__drop-menu user-menu">
                            <ul>
                                <li>
                                    <a href="/"><i className="icon-user"></i></a>
                                    <ul>
                                        <li><a href="/login">Sign in</a></li>
                                        <li><a href="/register">Register</a></li>
                                        <li><a href="/myaccount">My Account</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                       
                        
                        
                        
                    </div>
                    {location.pathname==='/'?(
                    <div className="col ltn__header-options d-lg-block mb-sm-10">
                        <div className="header-contact-search">
                            <div className="header-search-2">
                            <form id="#123" method="get"  action="/products">
                                    <input type="text" name="search" value={search} className="search_top mb-2" placeholder="Search here..."/>
                                    <ReactSearchAutocomplete
                                        items={products}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        autoFocus
                                        formatResult={formatResult}
                                        placeholder="Search Here.."
                                        showIcon false
                                        fuseOptions={{
                                            keys: ['name'],
                                            minMatchCharLength: 3   ,
                                          }}
                                          styling={{
                                            border: '1px',
                                            clearIconMargin: '0px 0px 0px 0px',
                                            iconColor: "#ffffff",
                                            hoverBackgroundColor: "#fff",
                                          }}
                                    />
                                    <button type="submit">
                                        <span><i className="icon-search"></i></span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    ):''}
                </div>
            </div>
        </div>
        
    </header>
    
    <div id="ltn__utilize-cart-menu" className="ltn__utilize ltn__utilize-cart-menu">
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
                <span className="ltn__utilize-menu-title">Cart</span>
                <button className="ltn__utilize-close">×</button>
            </div>
            <div className="mini-cart-product-area ltn__scrollbar">
            {(Array.isArray(cart) && cart.length)
                ? cart.map((curElem) => {
                    return <CartItemSidebar key={curElem.id} {...curElem} />;
                })
            : null}
            </div>
            <div className="mini-cart-footer">
                <div className="mini-cart-sub-total">
                    <h5>Subtotal: <span><FormatPrice price={total_price} /></span></h5>
                </div>
                <div className="btn-wrapper">
                    <a href="/cart" className="theme-btn-1 btn">View Cart</a>
                    <a href="/checkout" className="theme-btn-2 btn">Checkout</a>
                </div>
                <p>Free Shipping on All Orders Over Rs. 100/-</p>
            </div>

        </div>
    </div>
    
    <div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
                <div className="site-logo">
                    <a href="index.html"><img src="/images/logo.png" alt="Logo"/></a>
                </div>
                <button className="ltn__utilize-close">×</button>
            </div>

            <div className="ltn__utilize-menu">
            <ul>
                <li><NavLink to='/' >Home</NavLink></li>
                <li ><NavLink to='/products' >Skin</NavLink>
                    <ul className="sub-menu">
                        <li><NavLink to='/products' >Ageing</NavLink></li>
                        <li><NavLink to='/products' >Acne & Acne Scars</NavLink></li>
                        <li><NavLink to='/products' >Damaged & Sensitive Skin</NavLink></li>
                        <li><NavLink to='/products' >Pigmentation</NavLink></li>
                        <li><NavLink to='/products' >Under Eye Concerns</NavLink></li>
                        <li><NavLink to='/products' >Stretch Marks</NavLink></li>
                    </ul>
                </li>
                <li ><NavLink to='/products' >Hair</NavLink>
                    <ul className="sub-menu">
                        <li><NavLink to='/products' >Dandruff</NavLink></li>
                        <li><NavLink to='/products' >Hair Fall & Hair Loss</NavLink></li>
                        <li><NavLink to='/products' >Damaged & Frizzy Hair</NavLink></li>
                        <li><NavLink to='/products' >Itchy & Irritated Scalp</NavLink></li>
                        <li><NavLink to='/products' >Bald Patches </NavLink></li>
                    </ul>
                </li>
                {/* <li><a href="blog.html">About Us</a></li>
                <li><a href="blog-grid.html">FAQs</a></li>
                <li><a href="blog-left-sidebar.html">Contact Us</a></li> */}
                
                
            </ul>
            </div>
            <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
                <ul>
                    {/* <li>
                        <a href="account.html" title="My Account">
                            <span className="utilize-btn-icon">
                                <i className="far fa-user"></i>
                            </span>
                            My Account
                        </a>
                    </li>
                    <li>
                        <a href="wishlist.html" title="Wishlist">
                            <span className="utilize-btn-icon">
                                <i className="far fa-heart"></i>
                                <sup>3</sup>
                            </span>
                            Wishlist
                        </a>
                    </li> 
                    <li>
                        <a href="/cart" title="Shoping Cart">
                            <span className="utilize-btn-icon">
                                <i className="fas fa-shopping-cart"></i>
                                <sup>5</sup>
                            </span>
                            Shoping Cart
                        </a>
                    </li>*/}
                </ul>
            </div>
            <div className="ltn__social-media-2">
                <ul>
                    <li><a href="/" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="/" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="/" title="Linkedin"><i className="fab fa-linkedin"></i></a></li>
                    <li><a href="/" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
   

    <div className="ltn__utilize-overlay"></div>
    </>
  );
};

export default Header;
