const HomePromoOffers = ()=>{
    return (

    <div className="ltn__banner-area mt-120--- hide">
        <div className="container">
            <div className="row ltn__custom-gutter--- justify-content-center">
                <div className="col-lg-4 col-sm-6">
                    <div className="ltn__banner-item">
                        <div className="ltn__banner-img">
                            <a href="shop.html"><img src="./images/banner/1.jpg" alt="Promo Banner"/></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="ltn__banner-item">
                        <div className="ltn__banner-img">
                            <a href="shop.html"><img src="./images/banner/2.jpg" alt="Banner 2"/></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="ltn__banner-item">
                        <div className="ltn__banner-img">
                            <a href="shop.html"><img src="./images/banner/3.jpg" alt="Banner 3"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    );

};
export default HomePromoOffers;