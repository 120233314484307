const cartReducer = (state, action) => {
  if (action.type === "ADD_TO_CART") {
    let { id, size, amount, discount_price, stock, attrs_id, product } = action.payload;
    // console.log(
    //   "🚀 ~ file: cartReducer.js ~ line 4 ~ cartReducer ~ product",
    //   product
    // );
    //let existingProduct;
    //if (Array.isArray(state.cart) && state.cart.length) 
    //{
      console.log('checking state:'+state.cart.length+'id:'+id+'size:'+size+'amount:'+amount+'attrs_id:'+attrs_id+'price:'+product.price);
      let existingProduct = state.cart.find(
        (curItem) => curItem.id === id + size
      );
    //}

    if (existingProduct) {
      let updatedProduct = state.cart.map((curElem) => {
        if (curElem.id === id + size) {
          let newAmount = curElem.amount + amount;

          if (newAmount >= curElem.max) {
            newAmount = curElem.max;
          }
          return {
            ...curElem,
            amount: newAmount,
          };
        } else {
          return curElem;
        }
      });
      return {
        ...state,
        cart: updatedProduct,
      };
    } else {
      let cartProduct = {
        id: id + size,
        product_id: id,
        name: product.name,
        size,
        amount,
        attrs_id: attrs_id,
        image: product.image,
        price: discount_price,
        max: stock,
      };

      return {
        ...state,
        cart: [...state.cart, cartProduct],
      };
    }

    
  }

  if (action.type === "ADD_CHECKOUT_DATA") {
    let { checkoutInput } = action.payload;
    // console.log(
    //   "🚀 ~ file: cartReducer.js ~ line 4 ~ cartReducer ~ product",
    //   product
    // );
    //let existingProduct;

      console.log('checking state:'+state.checkout.length+', fullname:'+checkoutInput.fullname);

      let checkoutData = {
        fullname: checkoutInput.fullname,
        phone: checkoutInput.phone,
        email: checkoutInput.email,
        address: checkoutInput.address,
        city: checkoutInput.city,
        state: checkoutInput.state,
        zipcode: checkoutInput.zipcode,
      };

      return {
        ...state,
        checkout:  checkoutData,
      };
  }

  if (action.type === "UPDATE_LOGIN") {
    let { userResponse } = action.payload;
      //console.log('checking state:'+state.checkout.length+', fullname:'+checkoutInput.fullname);

      let currentUserData = {
        userid: userResponse.userid,
        userName: userResponse.userName,
        userEmail: userResponse.userEmail,
        userMobile: userResponse.userMobile,
      };

      return {
        ...state,
        current_user:  currentUserData,
      };
  }

  if (action.type === "UPDATE_CUSTOMER_ORDERS") {

      return {
        ...state,
        customer_orders:  action.payload,
      };
  }
  
  if (action.type === "LOGOUT_USER") {
    

      return {
        ...state,
        current_user:  [],
      };
  }

  // to set the increment and decrement
  if (action.type === "SET_DECREMENT") {
    let updatedProduct = state.cart.map((curElem) => {
      if (curElem.id === action.payload) {
        let decAmount = curElem.amount - 1;

        if (decAmount <= 1) {
          decAmount = 1;
        }

        return {
          ...curElem,
          amount: decAmount,
        };
      } else {
        return curElem;
      }
    });
    return { ...state, cart: updatedProduct };
  }

  if (action.type === "SET_INCREMENT") {
    let updatedProduct = state.cart.map((curElem) => { 
      if (curElem.id === action.payload) {
        let incAmount = curElem.amount + 1;
        
        if (incAmount >= curElem.max) {
          incAmount = curElem.max;
        }

        return {
          ...curElem,
          amount: incAmount,
        };
      } else {
        return curElem;
      }
    });
    return { ...state, cart: updatedProduct };
  }

  if (action.type === "REMOVE_ITEM") {
    let updatedCart = state.cart.filter(
      (curItem) => curItem.id !== action.payload
    );
    return {
      ...state,
      cart: updatedCart,
    };
  }

  // to empty or to clear to cart
  if (action.type === "CLEAR_CART") {
    return {
      ...state,
      cart: [],
    };
  }

  if (action.type === "CART_TOTAL_ITEM") { 
    if (Array.isArray(state.cart) && state.cart.length) 
    {
      let updatedItemVal = state.cart.reduce((initialVal, curElem) => {
        let { amount } = curElem;

        initialVal = initialVal + amount;
        return initialVal;
      }, 0);

      return {
        ...state,
        total_item: updatedItemVal,
      };

    }
    else{
      return {
        ...state,
        total_item: 0,
      };

    }

    
  }

  if (action.type === "CART_TOTAL_PRICE") {
      let total_price = state.cart.reduce((initialVal, curElem) => {
        let { price, amount } = curElem;

        initialVal = initialVal + price * amount;
        // 25000 + 0 = 25000
        // 10199 + 25000 = 121

        return initialVal;
      }, 0);

      return {
        ...state,
        total_price,
      };

    
  }

  if (action.type === "UPDATE_PAYMENT_ID") { 
      return {
        ...state,
        payment_id: action.payload,
      };
  }

  return state;
};

export default cartReducer;
