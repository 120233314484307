import { loadJS } from "./Helpers/GlobalFunctions";
import { useEffect } from "react";
import CartItemForCheckout from "./components/CartItemForCheckout";
import FormatPrice from "./Helpers/FormatPrice";
import { useCartContext } from "./context/cart_context";

const Thankyou = () =>{
    const { cart, checkout, payment_id, total_price, shipping_fee } = useCartContext();    

    useEffect(() => {  
        loadJS("./js/plugins.js", false);
        loadJS("./js/main.js", false);
        
    });
    useEffect(() => {
        window.scrollTo(0, 0);
        window.gtag('config', 'AW-11468377819');
        window.gtag('event', 'conversion', {'send_to': 'AW-11468377819'});
    }, []);

    

    return (
        <>
        <div className="ltn__checkout-area mb-120 text-left">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 pl-0 pr-0">
                        <div className="ltn__checkout-inner">
                            <div className="ltn__checkout-single-content mt-20">
                                <h4 className="title-2 text-center">You order has been successfully completed.</h4>
                                <p className="text-center">Our excutive team call you shortly or please call (+91-8368445108)</p>
                                <div className="ltn__checkout-single-content-info">
                                    <div className="row mt-5 mb-5">
                                        <div className="col-lg-12">
                                            <strong>Order No.:</strong> #{payment_id}
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-5">
                                        <div className="col-lg-4">
                                            <strong>Recipient Name:</strong> {checkout.fullname}
                                        </div>
                                        <div className="col-lg-4">
                                            <strong>Mobile Number:</strong> {checkout.phone}
                                        </div>
                                        <div className="col-lg-4">
                                            <strong>Email Address:</strong> {checkout.email}
                                        </div>
                                    </div>    
                                    <div className="row mt-5 mb-5">
                                        <div className="col-lg-12">
                                            <strong>Shipping Address:</strong> {checkout.address}, {checkout.city}, {checkout.state}, {checkout.zipcode}
                                        </div>
                                    </div>  
                                    <div className="row mt-5 mb-5 hide">
                                        <div className="col-lg-4">
                                            <strong>Promo Coupon:</strong> Senior Heart Surgeon
                                        </div>
                                        <div className="col-lg-4">
                                            <strong>Promo Percent:</strong> Senior Heart Surgeon
                                        </div>
                                        <div className="col-lg-4">
                                            <strong>Discount Amount:</strong> Senior Heart Surgeon
                                        </div>
                                    </div>  
                                    <div className="row mt-5 mb-5">
                                        <div className="col-lg-4">
                                            <strong>Delivery Charge:</strong> {shipping_fee}
                                        </div>
                                        <div className="col-lg-4">
                                            <strong>Total Amount:</strong> {total_price}
                                        </div>
                                        <div className="col-lg-4">
                                            <strong>Message:</strong> {checkout.message}
                                        </div>
                                    </div>   
                                    <div className="row mt-5 mb-5">
                                        <div className="col-lg-6">
                                            <h4 className="title-2">Order Summary</h4>
                                            <table className="table">
                                                <tbody>
                                                {(Array.isArray(cart) && cart.length)
                                                    ? cart.map((curElem) => {
                                                        return <CartItemForCheckout key={curElem.id} {...curElem} />;
                                                    })
                                                : null}
                                                    
                                                    <tr>
                                                        <td>Shipping and Handing</td>
                                                        <td><FormatPrice price={shipping_fee} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Order Total</strong></td>
                                                        <td><strong><FormatPrice price={shipping_fee + total_price} /></strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        </>
    );
}; 
export default Thankyou;