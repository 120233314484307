import { useFilterContext } from "../context/filter_context";
//import { FaCheck } from "react-icons/fa";
//import FormatPrice from "../Helpers/FormatPrice";
import { Button } from "../styles/Button";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const FilterSection = () => {
  const {
    filters: {text, category, maxPrice, minPrice },
    updateFilterValue,
    updateFilterValueManually,
    all_products,
    clearFilters,
  } = useFilterContext();

  // get the unique values of each property
  const getUniqueData = (data, attr) => {
    let newVal = data.map((curElem) => {
      return curElem[attr];
    });

    if (attr === "sizes") {
      // return (newVal = ["All", ...new Set([].concat(...newVal))]);
      newVal = newVal.flat();
    }

    return (newVal = ["all", ...new Set(newVal)]);
  };

  // we need to have the individual data of each in an array format
  const categoryData = getUniqueData(all_products, "category");
  //const companyData = getUniqueData(all_products, "company");
  //const sizesData = getUniqueData(all_products, "sizes");
  // console.log(
  //   "🚀 ~ file: FilterSection.js ~ line 23 ~ FilterSection ~ companyData",
  //   sizesData
  // );
  const [searchParams] = useSearchParams();
  const getDefaultText =()=>{
    const QuerySearch = searchParams.get('search'); // 'search params'
    console.log("search:",QuerySearch);
    if (QuerySearch === undefined || QuerySearch === null) { 
      return "";
    } else { 
      return QuerySearch;
    }
  };
  const defaultText = getDefaultText();


  useEffect(() => {
    updateFilterValueManually(defaultText);
  }, [defaultText]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="filter-search hide">
        <form  onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            name="text"
            placeholder="Search"
            value={text === "" ? defaultText : text}
            onChange={updateFilterValue}
          />
        </form>
      </div>
      <div className="widget ltn__menu-widget">
        <h4 className="ltn__widget-title ltn__widget-title-border">Product categories</h4>
        <ul>
          {categoryData.map((curElem, index) => {
              return (
                
                <li key={index}><button
                key={index}
                type="button"
                name="category"
                value={curElem}
                className={curElem === category ? "active" : ""}
                onClick={updateFilterValue}>
                {curElem} 
              </button> <span><i className="fas fa-long-arrow-alt-right"></i></span></li>
              );
            })}
            
          </ul>
    </div>

    <div className="widget ltn__price-filter-widget">
        <h4 className="ltn__widget-title ltn__widget-title-border">Filter by price</h4>
        <div className="price_filter">
            <div className="price_slider_amount">
                <input type="submit"  value="Your range:"/> 
                <input type="text" className="amount" min={minPrice} max={maxPrice}  name="price" onChange={updateFilterValue}  placeholder="Add Your Price" /> 
            </div>
            <div className="slider-range"></div>
        </div>
        {/* <input
          type="range"
          name="price"
          min={minPrice}
          max={maxPrice}
          value={price}
          onChange={updateFilterValue}
        /> */}
    </div>

    <div className="widget ltn__price-filter-widget">
        <Button className="btn" onClick={clearFilters}>
          Clear Filters
        </Button>

    </div>


    {/* <div className="widget ltn__banner-widget">
        <a href="shop.html"><img src="../images/banner/banner-2.jpg" alt="#"/></a>
    </div>
      <div className="filter-search">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            name="text"
            placeholder="Search"
            value={text}
            onChange={updateFilterValue}
          />
        </form>
      </div>

      <div className="filter-category">
        <h3>Category</h3>
        <div>
          
        </div>
      </div>

      <div className="filter-company">
        <h3>Company</h3>

        <form action="#">
          <select
            name="company"
            id="company"
            className="filter-company--select"
            onClick={updateFilterValue}>
            {companyData.map((curElem, index) => {
              return (
                <option key={index} value={curElem} name="company">
                  {curElem}
                </option>
              );
            })}
          </select>
        </form>
      </div>

      <div className="filter-colors colors">
        <h3>Colors</h3>

        <div className="filter-color-style">
          {sizesData.map((curColor, index) => {
            if (curSize === "all") {
              return (
                <button
                  key={index}
                  type="button"
                  value={curSize}
                  name="color"
                  className="color-all--style"
                  onClick={updateFilterValue}>
                  all
                </button>
              );
            }
            return (
              <button
                key={index}
                type="button"
                value={curSize}
                name="color"
                style={{ backgroundColor: curSize }}
                className={size === curSize ? "btnStyle active" : "btnStyle"}
                onClick={updateFilterValue}>
                {size === curSize ? <FaCheck className="checkStyle" /> : null}
              </button>
            );
          })}
        </div>
      </div>

      <div className="filter_price">
        <h3>Price</h3>
        
      </div>

      <div className="filter-clear">
        
      </div> */}
    </>
  );
};


export default FilterSection;
