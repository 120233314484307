import { createContext, useContext, useReducer, useEffect } from "react";
import reducer from "../reducer/cartReducer";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';

const CartContext = createContext();

const getLocalCartData = () => {
  let localCartData = JSON.parse(localStorage.getItem("rxkinCart"));
  if (localCartData === undefined || localCartData === null) { 
    return [];
  } else { 
    return localCartData;
  }
};

const getCurrentUserData = () => {
  let currentUserData = JSON.parse(localStorage.getItem("current_user"));
  if (currentUserData === undefined || currentUserData === null) { 
    return [];
  } else { 
    return currentUserData;
  }
};

const getLocalCheckoutData = () => {
  let localCheckoutData = JSON.parse(localStorage.getItem("rxkinCheckout"));
  if (localCheckoutData === undefined || localCheckoutData === null) { 
    return [];
  } else { 
    return localCheckoutData;
  }
};

const getLocalSessionId = () => {
  let LocalSessionId = localStorage.getItem('sessionId');
  if (LocalSessionId === undefined || LocalSessionId === null) { 
    const unique_id = uuidv4();
    const small_id = unique_id.slice(0, 8);
    return localStorage.setItem('sessionId', small_id);
  } else { 
    return LocalSessionId;
  }
};

const getLocalPaymentId = () => {
  let LocalPaymentId = localStorage.getItem('payment_id');
  if (LocalPaymentId === undefined || LocalPaymentId === null) { 
    return "";
  } else { 
    return LocalPaymentId;
  }
};



const initialState = {
  //cart: [],
  cart: getLocalCartData(),
  current_user: getCurrentUserData(),
  customer_orders: [],
  checkout: getLocalCheckoutData(),
  total_item: "",
  total_price: "",
  shipping_fee: 0,
  sessionId: getLocalSessionId(),
  payment_id: getLocalPaymentId(),
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addToCart = (id, size, amount, discount_price, stock, attrs_id, product) => {
    dispatch({ type: "ADD_TO_CART", payload: { id, size, amount, discount_price, stock, attrs_id, product } });
  };

  const checkLogin = async (email, password) => {
    const userData = {
        email: email,
        password: password,
    };
    dispatch({ type: "SET_LOADING" });
    try { 
      const res = await axios.post("https://shop.rxkin.com/apis/check_login.php", JSON.stringify(userData));
      //alert('asdfddd');
      //console.log("place order:"+res);
      const userResponse = await res.data;
      if(userResponse)
      {
        //localStorage.setItem('current_user', userResponse);
        //console.log("userResponse:"+userResponse.userName);
        dispatch({ type: "UPDATE_LOGIN", payload: { userResponse} });
        return userResponse;
      }
      else
      {
        dispatch({ type: "API_ERROR" });
      }
      
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    
  };

  const getCustomerOrders = async () => {
    if(initialState.current_user.userid && initialState.current_user.userid!=='')
    {
      const userData = {
          userid: initialState.current_user.userid,
      };
      dispatch({ type: "SET_LOADING" });
      try { 
        const res = await axios.post("https://shop.rxkin.com/apis/customer_orders.php", JSON.stringify(userData));
        //alert('asdfddd');
        //console.log("cust order:"+res);
        const orderResponse = await res.data;
        if(orderResponse)
        {
          //localStorage.setItem('current_user', userResponse);
          //console.log("userResponse:"+userResponse.userName);
          dispatch({ type: "UPDATE_CUSTOMER_ORDERS", payload: { orderResponse} });
        }
        else
        {
          dispatch({ type: "API_ERROR" });
        }
        
      } catch (error) {
        dispatch({ type: "API_ERROR" });
      }

    }
    
    console.log('current_user data:',initialState.current_user.userid);
  };

  const LogoutUser = async () => {
    dispatch({ type: "LOGOUT_USER"});
    return true;
  };
  
  const userRegistration = async (userInput) => {

    dispatch({ type: "SET_LOADING" });
    try { 
      const res = await axios.post("https://shop.rxkin.com/apis/user_registration.php", JSON.stringify(userInput));
      //alert('asdfddd');
      //console.log("place order:"+res);
      const userResponse = await res.data;
      if(userResponse)
      {
        //localStorage.setItem('current_user', userResponse);
        //console.log("userResponse:"+userResponse.userName);
        return userResponse;
      }
      else
      {
        dispatch({ type: "API_ERROR" });
      }
      
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
    
  };

  const addCheckoutData = (checkoutInput) => {
    dispatch({ type: "ADD_CHECKOUT_DATA", payload: { checkoutInput } });
    
  };
  // increment and decrement the product

  const placeOrder = async (orderData) => {
    dispatch({ type: "SET_LOADING" });
    try { 
      const res = await axios.post("https://shop.rxkin.com/apis/place_order.php", JSON.stringify(orderData));
      //alert('asdfddd');
      //console.log("place order:"+res);
      const payment_id = await res.data;
      localStorage.setItem('payment_id', payment_id);
      console.log("orderid:"+payment_id);
      dispatch({ type: "UPDATE_PAYMENT_ID", payload: payment_id });
      return payment_id;
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const confirmOrder = async (responseData ) => {
    dispatch({ type: "SET_LOADING" });
    try { 
      const res = await axios.post("https://shop.rxkin.com/apis/confirm_order.php", JSON.stringify(responseData));
      //alert('asdfddd');
      //console.log(res);
      const payment_id = await res.data;
      localStorage.removeItem('payment_id');
      localStorage.removeItem('rxkinCart');
      localStorage.removeItem('rxkinCheckout');
      //console.log("paymentid:"+payment_id);
      //dispatch({ type: "UPDATE_PAYMENT_ID", payload: payment_id });
      return payment_id;
      
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };

  const setDecrease = (id) => { 
    dispatch({ type: "SET_DECREMENT", payload: id });
  };

  const setIncrement = (id) => {
    dispatch({ type: "SET_INCREMENT", payload: id });
  };

  const removeItem = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: id });
  };

  const delhiveryServiceability = (pincode) => {
    return new Promise(async (resolve, reject) => {
      let resData = {
        status: false,
        mainToken: {},
        message: "Fail!!",
      };
      try {
        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        let parmers = 'filter_codes=' + pincode;
        //parmers += '&delivery_postcode=' + delivery_postcode;
       // parmers += '&weight=' + weight;
        
        //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
          var config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://track.delhivery.com/c/api/pin-codes/json/?' + parmers,
            headers: {
              'Content-Type': 'application/json',
              'accept': 'application/json',
              'Authorization': 'Bearer 22b460dcf8b68ffaaf4334ba9eb062c3cc6a63f0'
            }
          };
          await axios(config).then(function(response) {
            resData.status = true;
            resData.message = 'Success!!';
            resData.mainset = response.data;
            resolve(resData);
            console.log(response.data);
          }).catch(function(error) {
            //++++++++++++++++++++++++++++++++++++
            console.log(error);
            console.log('srShippingRateCalculation');
            //++++++++++++++++++++++++++++++++++++
            resData.status = false;
            resData.message = 'Error!!';
            resData.mainset = JSON.stringify(error);
            reject(resData);
          });

      } catch (e) {
        //+++++++++++++++++++++++++++++++++++++++++
        console.error(e);
        console.log('srShippingRateCalculation');
        //+++++++++++++++++++++++++++++++++++++++++
        reject(resData);
      }
    });
  }

  useEffect(() => { 
    dispatch({ type: "CART_TOTAL_ITEM" });
    dispatch({ type: "CART_TOTAL_PRICE" });
    localStorage.setItem("rxkinCart", JSON.stringify(state.cart));
    localStorage.setItem("rxkinCheckout", JSON.stringify(state.checkout));
    localStorage.setItem("current_user", JSON.stringify(state.current_user));
    getCustomerOrders();
  }, [state.cart, state.checkout, state.current_user]);

  useEffect(() => {
    getCustomerOrders();
  }, []);

  return (
    <CartContext.Provider value={{ ...state, addToCart, checkLogin, LogoutUser, userRegistration, addCheckoutData, placeOrder, confirmOrder, removeItem, setDecrease, setIncrement, delhiveryServiceability }}>
      {children}
    </CartContext.Provider>
  );
};

const useCartContext = () => {
  return useContext(CartContext);
};

export { CartProvider, useCartContext };
