//import styled from "styled-components";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useCartContext } from "./context/cart_context";
import CartItem from "./components/CartItem";
import { loadJS } from "./Helpers/GlobalFunctions";
import FormatPrice from "./Helpers/FormatPrice";

const Cart = () => {

    useEffect(() => {  
        loadJS("./js/plugins.js", false);
        loadJS("./js/main.js", false);
      });

  const { cart, total_price, shipping_fee } = useCartContext();
  //console.log("🚀 ~ file: Cart.js ~ line 6 ~ Cart ~ cart", cart);
  
  useEffect(() => { 
    
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 300)
  }, []);
  
  return (
    <>
      {/*<div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "  data-bs-bg="../images/bg/14.jpg">
           <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="ltn__breadcrumb-inner">
                          <div className="ltn__breadcrumb-list">
                              <ul>
                                  <li><a href="index.html"><span className="ltn__secondary-color"><i className="fas fa-home"></i></span> Home</a></li>
                                  <li>Cart</li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div> 
      </div>*/}

      <div className="liton__shoping-cart-area mb-120 text-left ">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="shoping-cart-inner">
                          <div className="shoping-cart-table table-responsive">
                              <table className="table">
                                  {/* <thead>
                                      <tr>
                                        <th className="cart-product-remove">Remove</th>
                                        <th className="cart-product-image">Image</th>
                                        <th className="cart-product-info">Product</th>
                                        <th className="cart-product-price">Price</th>
                                        <th className="cart-product-quantity">Quantity</th>
                                        <th className="cart-product-subtotal">Subtotal</th>
                                      </tr>
                                  </thead> */}
                                  <tbody>
                                    
                                    
                                  {(Array.isArray(cart) && cart.length)
                                        ? cart.map((curElem) => {
                                                return <CartItem key={curElem.id} {...curElem} />;
                                            })
                                        : null}
                                    
                                    
                                  </tbody>
                              </table>
                          </div>
                          <div className="shoping-cart-total mt-50">
                              <h4>Cart Totals</h4>
                              <table className="table">
                                  <tbody>
                                      <tr>
                                          <td>Cart Subtotal</td>
                                          <td><FormatPrice price={total_price} /></td>
                                      </tr>
                                      <tr>
                                          <td>Shipping and Handing</td>
                                          <td><FormatPrice price={shipping_fee} /></td>
                                      </tr>
                                      <tr>
                                          <td><strong>Order Total</strong></td>
                                          <td><strong><FormatPrice price={shipping_fee + total_price} /></strong></td>
                                      </tr>
                                  </tbody>
                              </table>
                              <div className="btn-wrapper text-right">
                                  <NavLink to={'/checkout'} className={'theme-btn-1 btn'}>Proceed to checkout</NavLink>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      {/* <div className="container">
        <div className="cart_heading grid grid-five-column">
          <p>Item</p>
          <p className="cart-hide">Price</p>
          <p>Quantity</p>
          <p className="cart-hide">Subtotal</p>
          <p>Remove</p>
        </div>
        <hr />

        <div className="cart-item">
          {cart.map((curElem) => {
            return <CartItem key={curElem.id} {...curElem} />;
          })}
        </div>
      </div> */}
    </>
  );
};


export default Cart;
