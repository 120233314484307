import {React} from "react";
//import { NavLink } from "react-router-dom";
import FormatPrice from "../Helpers/FormatPrice";

const OrderItem = (curElem) => {
  const { id, pay_id, dateInLocal, transaction_type, payment_gateway, amount_paid, status, items } = curElem;
  console.log('pay_id',pay_id);
//   const stock = inventory && inventory[0].attr_stock;
//   const price = inventory && inventory[0].attr_oaprice;
//   const Discount_Price = inventory && inventory[0].attr_odprice;
  
//   const disPercentage = (price,Discount_Price)=>{
//     const discount = price - Discount_Price;
//     const disPerc =  discount/price*100;
//     return (
//       <div className="product-badge">
//                   <ul>
//                       <li className="sale-badge">Save {disPerc.toFixed(2)}%</li>
//                   </ul>
//               </div>
//     )
//   }
  
  return (
    <>
    <tr>
        <td>#{pay_id}</td>
        <td>{dateInLocal}</td>
        <td>{status}</td>
        <td><FormatPrice price={amount_paid} /></td>
        <td>{payment_gateway==='razorpay'?'Online':'COD'}</td>
    </tr>
    <tr>
      <td colSpan="5" class="orderproddetail">
        <table width="100%">
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
          {items && items.map((curElem) => {
              return (
                <tr>
                  <td><img src={curElem.productimage} width="20" /> {curElem.prod_name}</td>
                  <td>{curElem.no_of_qty}</td>
                  <td><FormatPrice price={curElem.total_price} /></td>
                </tr>
              )
          })}
          
        </table>  
      </td>
    </tr>
    </>
  );
};

export default OrderItem;
