import { useEffect, useState } from "react";
import styled from "styled-components";
//import { FaCheck } from "react-icons/fa";
import CartAmountToggle from "./CartAmountToggle";
import { NavLink, useLocation } from "react-router-dom";
//import { Button } from "../styles/Button";
import { useCartContext } from "../context/cart_context";
import FormatPrice from "../Helpers/FormatPrice";



const AddToCartDetail = ({ product }) => {
  const { addToCart } = useCartContext();

  const { id, inventory } = product;
  //const defaultsize = inventory[0].attr_name;
  //console.log("product:"+inventory[0].attr_name);
  const [size, setSize] = useState('');
  const [amount, setAmount] = useState(1);
  const [stock, setStock] = useState(0);
  const [price, setPrice] = useState(0);
  const [discount_price, setDiscountPrice] = useState(0);
  const [attrs_id, setAttrs_id] = useState("");


  const setDecrease = () => {
    amount > 1 ? setAmount(amount - 1) : setAmount(1);
  };

  const setIncrease = () => {
    amount < stock ? setAmount(amount + 1) : setAmount(stock);
  };

  const location = useLocation();

  useEffect(() => {
    
 
    const newPrice = inventory && inventory[0].attr_oaprice;
    const newDiscount_Price = inventory && inventory[0].attr_odprice;
    const newSize = inventory && inventory[0].attr_name;
    const newStock = inventory && inventory[0].attr_stock;
    const newAttrs_id = inventory && inventory[0].attr_id;
    

    setStock(newStock);
    setSize(newSize);
    setPrice(newPrice);
    setDiscountPrice(newDiscount_Price);
    setAttrs_id(newAttrs_id);
    
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  
  
  function changeInventory(attr_name,attr_oaprice,attr_odprice,attr_stock,attr_id)
  { 
    setSize(attr_name);
    setStock(attr_stock);
    setPrice(attr_oaprice);
    setDiscountPrice(attr_odprice);
    setAttrs_id(attr_id);
    
  }

  const disPercentage = (price,Discount_Price)=>{
    const discount = price - Discount_Price;
    const disPerc =  discount/price*100;
    return (
      
      <span className="dis_percnt">&nbsp;({disPerc.toFixed(2)}% OFF)</span>
                  
    )
  }


  return (
    <Wrapper>
        
      <div className="product-price">
              <span><FormatPrice price={discount_price * amount} /></span>
              <del><FormatPrice price={price * amount} /></del>
              {location.pathname.includes('singleproduct') && price && discount_price && disPercentage(price,discount_price)}
      </div> 
      <div className="ltn__product-details-menu-2">
            <div className="colors">
                <p>
                Size:
                {inventory && inventory.map((curElm, index) => {

                    return (
                    <button
                        key={index}
                        className={size === curElm.attr_name ? "btnStyle active" : "btnStyle"}
                        onClick={() => changeInventory(curElm.attr_name,curElm.attr_oaprice,curElm.attr_odprice, curElm.attr_stock, curElm.attr_id)}>
                        <span>{curElm.attr_name}</span>
                    </button>
                    );
                })}
                </p>
            </div>

            {/* add to cart  */}
            <CartAmountToggle
                amount={amount}
                setDecrease={setDecrease}
                setIncrease={setIncrease}
            />

            <NavLink to="/cart" className={"theme-btn-1 btn "+(location.pathname.includes('singleproduct')?"desktop":"")} onClick={() => addToCart(id, size, amount, discount_price, stock, attrs_id, product)}>
            <i className="fas fa-shopping-cart"></i> <span>Add To Cart</span>
            </NavLink>
            {location.pathname.includes('singleproduct')?(
              <div className="ltn__product-details-menu-2 fixed-bottom mobile">
                <ul>
                <li className="col-sm-6 col-6 m-0">
                    <NavLink to="/cart" className={"theme-btn-2 btn "} onClick={() => addToCart(id, size, amount, discount_price, stock, attrs_id, product)}>
                    <i className="fas fa-shopping-cart"></i> <span>Add To Cart</span>
                    </NavLink>
                    
                </li>
                <li className="col-sm-6 col-6 m-0 pl-0">
                    <NavLink to="/checkout" className={"theme-btn-1 btn"} onClick={() => addToCart(id, size, amount, discount_price, stock, attrs_id, product)}>
                    <span>Buy it Now</span>
                    </NavLink>
                </li>
                </ul>
            </div>):''
            }
        </div>  
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .colors p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .btnStyle {
  width: auto;
  height: auto;
  background-color: #ecfbf9;
  border-radius: 5px;
  margin-left: 1rem;
  border: 1px solid #eee;
  outline: none;
  opacity: 0.5;
  cursor: pointer;
  padding: 3px 10px;

    &:hover {
      opacity: 1;
    }
  }

  .active {
    opacity: 1;
    background-color:#c6f7f0;
  }

  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }

  /* we can use it as a global one too  */
  .amount-toggle {
    margin-top: 5px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.4rem;
    border:1px solid #eee;

    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }

    .amount-style {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.btn};
      border-right: 1px solid #eee;
      border-left: 1px solid #eee;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;
export default AddToCartDetail;
