import { loadJS } from "./Helpers/GlobalFunctions";
import { useEffect } from "react";
import { useCartContext } from "./context/cart_context";
import {useNavigate } from "react-router-dom";


const Logout = () =>{
    const {LogoutUser } = useCartContext();    
    const navigate = useNavigate();

    const logoutResponse = LogoutUser();
        console.log('logoutResponse:',logoutResponse);
        if(logoutResponse && logoutResponse!==null)
        { 
            navigate('/');
        }

    useEffect(() => {  
        loadJS("./js/plugins.js", false);
        loadJS("./js/main.js", false);
        
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

 

    return (
        <>
        <div className="ltn__checkout-area mb-120 text-left">

        </div>    
        
        </>
    );
}; 
export default Logout;