const HomeCategory = () => {


    return (


    <div className="ltn__category-area section-bg-1-- pt-50 pb-90">
        <div className="container">
            <div className="row ltn__category-slider-active-six slick-arrow-1 border-bottom">
                <div className="col-12">
                    <div className="ltn__category-item ltn__category-item-6 text-center">
                        <div className="ltn__category-item-img">
                            <a href="/products">
                                <i className="fas fa-notes-medical"></i>
                            </a>
                        </div>
                        <div className="ltn__category-item-name">
                            <h6><a href="/products">Best Deals</a></h6>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="ltn__category-item ltn__category-item-6 text-center">
                        <div className="ltn__category-item-img">
                            <a href="/products">
                                <i className="fas fa-box-tissue"></i>
                            </a>
                        </div>
                        <div className="ltn__category-item-name">
                            <h6><a href="/products">Germs Pads</a></h6>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="ltn__category-item ltn__category-item-6 text-center">
                        <div className="ltn__category-item-img">
                            <a href="/products">
                                <i className="fas fa-pump-medical"></i>
                            </a>
                        </div>
                        <div className="ltn__category-item-name">
                            <h6><a href="/products">Accessories</a></h6>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="ltn__category-item ltn__category-item-6 text-center">
                        <div className="ltn__category-item-img">
                            <a href="/products">
                                <i className="fas fa-bong"></i>
                            </a>
                        </div>
                        <div className="ltn__category-item-name">
                            <h6><a href="/products">Skin Care</a></h6>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="ltn__category-item ltn__category-item-6 text-center">
                        <div className="ltn__category-item-img">
                            <a href="/products">
                                <i className="fas fa-microscope"></i>
                            </a>
                        </div>
                        <div className="ltn__category-item-name">
                            <h6><a href="/products">Best Deals</a></h6>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="ltn__category-item ltn__category-item-6 text-center">
                        <div className="ltn__category-item-img">
                            <a href="/products">
                                <i className="fas fa-syringe"></i>
                            </a>
                        </div>
                        <div className="ltn__category-item-name">
                            <h6><a href="/products">All Products</a></h6>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="ltn__category-item ltn__category-item-6 text-center">
                        <div className="ltn__category-item-img">
                            <a href="/products">
                                <i className="fas fa-stethoscope"></i>
                            </a>
                        </div>
                        <div className="ltn__category-item-name">
                            <h6><a href="/products">Germs Pads</a></h6>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="ltn__category-item ltn__category-item-6 text-center">
                        <div className="ltn__category-item-img">
                            <a href="/products">
                                <i className="fas fa-hand-holding-medical"></i>
                            </a>
                        </div>
                        <div className="ltn__category-item-name">
                            <h6><a href="/products">Accessories</a></h6>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="ltn__category-item ltn__category-item-6 text-center">
                        <div className="ltn__category-item-img">
                            <a href="/products">
                                <i className="fas fa-procedures"></i>
                            </a>
                        </div>
                        <div className="ltn__category-item-name">
                            <h6><a href="/products">Hair Care</a></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


        );
};


export default HomeCategory;