const ProdImages = ({ imgs = [{ url: "" }] }) => {


  return (
    <>
      <div className="ltn__shop-details-large-img">
        {imgs.map((curElm, index) => {
          return (
            <div className="single-large-img" key={index} >
                {/* <Link to={curElm} data-rel="lightcase:myCollection"> */}
                    <img src={curElm} alt={curElm} />
                {/* </Link> */}
            </div>
            
          );
        })}
      </div>
      {/* 2nd column  */}
      <div className="ltn__shop-details-small-img slick-arrow-2">
        {imgs.map((curElm, index) => {
          return (
            <div className="single-small-img" key={index}>
              <img src={curElm} alt=""  />
            </div>
          );
        })}
        
      </div>
      
    </>
  );
};


export default ProdImages;
