//import { NavLink } from "react-router-dom";
import {useProductContext} from "../context/productcontex";
import Product2 from "./Product2";

const HomeLatestProducts = ()=>{

    const { isLoading, newProducts } = useProductContext();

    if (isLoading) {
        return <div> ......Loading </div>;
    }

    return (

    <div className="ltn__product-area ltn__product-gutter pt-115 pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title-area ltn__section-title-2 text-center">
                        <h1 className="section-title">Leatest Product</h1>
                    </div>
                </div>
            </div>
            <div className="row ltn__tab-product-slider-one-active--- slick-arrow-1">

                {newProducts.slice(0, 8).map((curElem) => {
                    return <Product2 key={curElem.id}{...curElem} />
                })}
                



            </div>
        </div>
    </div>

    );
};
export default HomeLatestProducts;