import React from "react";
import FormatPrice from "../Helpers/FormatPrice";
import { useCartContext } from "../context/cart_context";

const CartItemSidebar = ({ id, name, image, size, price, amount }) => {
    const { removeItem} = useCartContext();

  return (
    <div className="mini-cart-item clearfix">
        <div className="mini-cart-img">
            <img src={image} alt={id} />
            <span className="mini-cart-item-delete"  onClick={() => removeItem(id)}><i className="icon-cancel"></i></span>
        </div>
        <div className="mini-cart-info">
            <h6>{name} <span>({size})</span></h6>
            <span className="mini-cart-quantity">{amount} x <FormatPrice price={price * amount} /></span>
        </div>
    </div>

   

  );
};

export default CartItemSidebar;
