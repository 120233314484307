import React from "react";
import QuickView from "./QuickView";
import WishList from "./WishList";
import AddToCartModel from "./AddToCartModel";
import { useEffect } from "react";


const Footer = () => {
    useEffect(() => {
        
        
      }, []);

  return (
    <>
    <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-1 plr--5">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                        <div className="footer-widget footer-about-widget">
                            <div className="footer-logo">
                                <div className="site-logo">
                                    <img src="/images/logo.png" alt="Logo"/>
                                </div>
                            </div>
                            <div className="footer-address">
                                <ul>
                                    <li>
                                        <div className="footer-address-icon">
                                            <i className="icon-placeholder"></i>
                                        </div>
                                        <div className="footer-address-info">
                                            <p>Ram Bagan - Ramkirishna Colony, P.O. Sear Sol Rajbari, Rajniganj - Burdawan, W. Bengal</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="footer-address-icon">
                                            <i className="icon-call"></i>
                                        </div>
                                        <div className="footer-address-info">
                                            <p><a href="tel:+91-8368445108">+91-8368445108</a></p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="footer-address-icon">
                                            <i className="icon-mail"></i>
                                        </div>
                                        <div className="footer-address-info">
                                            <p><a href="mailto:info@rxkin.com">info@rxkin.com</a></p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="ltn__social-media mt-20">
                                <ul>
                                    <li><a href="/" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="/" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="/" title="Linkedin"><i className="fab fa-linkedin"></i></a></li>
                                    <li><a href="/" title="Youtube"><i className="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                        <div className="footer-widget footer-menu-widget clearfix">
                            <h4 className="footer-title">Company</h4>
                            <div className="footer-menu">
                                <ul>
                                    <li><a href="/aboutus">About</a></li>
                                    {/* <li><a href="/blog">Blog</a></li> */}
                                    <li><a href="/products">Hair Care</a></li>
                                    <li><a href="/products">Skin Care</a></li>
                                    <li><a href="/products">All Products</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                        <div className="footer-widget footer-menu-widget clearfix">
                            <h4 className="footer-title">Customer Care</h4>
                            <div className="footer-menu">
                                <ul>
                                    <li><a href="/terms">Terms & Conditions</a></li>
                                    <li><a href="/privacy_policy">FAQ</a></li>
                                    <li><a href="/contact">Contact us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                        <div className="footer-widget footer-newsletter-widget">
                            <h4 className="footer-title">Newsletter</h4>
                            <p>Subscribe to our weekly Newsletter and receive updates via email.</p>
                            <div className="footer-newsletter">
                                <form action="#">
                                    <input type="email" name="email" placeholder="Email*"/>
                                    <div className="btn-wrapper">
                                        <button className="theme-btn-1 btn" type="submit"><i className="fas fa-location-arrow"></i></button>
                                    </div>
                                </form>
                            </div>
                            <h5 className="mt-30">We Accept</h5>
                            <img src="/images/icons/payment-4.png" alt="Payment "/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
            <div className="container-fluid ltn__border-top-2">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="ltn__copyright-design clearfix">
                            <p>All Rights Reserved @ RXKIN <span className="current-year"></span></p>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="ltn__copyright-menu text-end">
                            <ul>
                                <li><a href="/terms">Terms & Conditions</a></li>
                                <li><a href="/privacy_policy">Privacy Policy</a></li>
                                <li><a href="/cancellation_policy">Cancellation Policy</a></li>
                                <li><a href="/refund_policy">Refund Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div className="preloader d-none" id="preloader">
        <div className="preloader-inner">
            <div className="spinner">
                <div className="dot1"></div>
                <div className="dot2"></div>
            </div>
        </div>
    </div>
    <QuickView/>
    <AddToCartModel/>
    <WishList/>
    </>
  );
};


export default Footer;
