import { NavLink } from "react-router-dom";
import FormatPrice from "../Helpers/FormatPrice";
//import { Button } from "../styles/Button";

const ListView = ({ products }) => {
  return (
    <div className="ltn__product-tab-content-inner ltn__product-grid-view product_list">
      <div className="row">
        {products.map((curElem) => {
          const { id, name, image, price, discount } = curElem;
          return (
            <>
            <div className="col-xl-4 col-sm-6 col-6">
              <div className="ltn__product-item ltn__product-item-3 text-center">
                  <div className="product-img">
                      <NavLink  to={`/singleproduct/${id}`} ><img src={image} alt={name}/></NavLink>
                      <div className="product-badge">
                          <ul>
                              <li className="sale-badge">New</li>
                          </ul>
                      </div>
                    
                  </div>
                  <div className="product-info">
                      
                      <h2 className="product-title"><NavLink to='/productdetail' >Digital Stethoscope</NavLink></h2>
                      <div className="product-price">
                          <span><FormatPrice price={discount} /></span>
                          <del><FormatPrice price={price} /></del>
                      </div>
                      <a href="/" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal" className="theme-btn-1 btn ">
                          <i className="fas fa-shopping-cart"></i>
                          <span>ADD TO CART</span>
                      </a>
                      
                  </div>
              </div>
            </div>

            </>
          );
        })}
      </div>
    </div>
  );
};


export default ListView;
