const WishList =()=>{
    return (
        <div className="ltn__modal-area ltn__add-to-cart-modal-area">
            <div className="modal fade" id="liton_wishlist_modal" >
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="ltn__quick-view-modal-inner">
                                <div className="modal-product-item">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="modal-product-img">
                                                <img src="../images/product/7.png" alt="#"/>
                                            </div>
                                            <div className="modal-product-info">
                                                <h5><a href="product-details.html">Digital Stethoscope</a></h5>
                                                <p className="added-cart"><i className="fa fa-check-circle"></i>  Successfully added to your Wishlist</p>
                                                <div className="btn-wrapper">
                                                    <a href="wishlist.html" className="theme-btn-1 btn btn-effect-1">View Wishlist</a>
                                                </div>
                                            </div>
                                            <div className="additional-info d-none">
                                                <p>We want to give you <b>10% discount</b> for your first order, <br/>  Use discount code at checkout</p>
                                                <div className="payment-method">
                                                    <img src="../images/icons/payment.png" alt="#"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WishList;