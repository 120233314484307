import {React} from "react";
import { NavLink } from "react-router-dom";
//import FormatPrice from "../Helpers/FormatPrice";
import AddToCartDetail from "./AddToCartDetail";


const Product = (curElem) => {
  const { slug, name, image, category, inventory } = curElem;
  //console.log(curElem);
  const stock = inventory && inventory[0].attr_stock;
  const price = inventory && inventory[0].attr_oaprice;
  const Discount_Price = inventory && inventory[0].attr_odprice;
  
  const disPercentage = (price,Discount_Price)=>{
    const discount = price - Discount_Price;
    const disPerc =  discount/price*100;
    return (
      <div className="product-badge">
                  <ul>
                      <li className="sale-badge">Save {disPerc.toFixed(2)}%</li>
                  </ul>
              </div>
    )
  }


  
  
  return (
    <>
    <div className="col-lg-3--- col-md-3 col-sm-6 col-6">
      <div className="ltn__product-item ltn__product-item-2 text-left">
          <div className="product-img">
          <NavLink to={`/singleproduct/${slug}`} title={category} ><img src={image} alt={name}/></NavLink>
              {price && Discount_Price && disPercentage(price,Discount_Price)}
              
          </div>
          <div className="product-info">
              {/* <div className="product-ratting">
                  <ul>
                      <li><a href="/"><i className="fas fa-star"></i></a></li>
                      <li><a href="/"><i className="fas fa-star"></i></a></li>
                      <li><a href="/"><i className="fas fa-star"></i></a></li>
                      <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                      <li><a href="/"><i className="far fa-star"></i></a></li>
                  </ul>
              </div> */}
              <h2 className="product-title"><NavLink to={`/singleproduct/${slug}`} >{name}</NavLink></h2>
              <div className="product-price">
                  {stock > 0 && <AddToCartDetail product={curElem} />}
              </div>
          </div>
      </div>
  </div>
  
    {/* <NavLink to={`/singleproduct/${id}`}>
      <div className="card">
        <figure>
          <img src={image} alt={name} />
          <figcaption className="caption">{category}</figcaption>
        </figure>

        <div className="card-data">
          <div className="card-data-flex">
            <h3>{name}</h3>
            <p className="card-data--price">{<FormatPrice price={price} />}</p>
          </div>
        </div>
      </div>
    </NavLink> */}
    </>
  );
};

export default Product;
