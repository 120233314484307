import { useEffect } from "react";
import { loadJS } from "./Helpers/GlobalFunctions";
import HomeAboutUs from "./components/HomeAboutUs";

const Aboutus = () => {

  useEffect(() => {  
    loadJS("./js/plugins.js", false);
    loadJS("./js/main.js", false);
  });

  return (
    <>
      <div className="ltn__checkout-area mb-120 text-left">
        <HomeAboutUs />
      </div>
    </>
  );
};

export default Aboutus;
