import React from "react";
import { useFilterContext } from "../context/filter_context";

const Sort = () => {
  // const { filter_products, grid_view, setGridView, setListView, sorting } =
  //   useFilterContext();
    const { filter_products, sorting } =
    useFilterContext();
  return (
    <>
      <ul>
        <li>
            {/* <div className="ltn__grid-list-tab-menu ">
                <div className="nav">
                    <a className={grid_view ? "active show " : ""}
          onClick={setGridView}  data-bs-toggle="tab" href="/"><i className="fas fa-th-large"></i></a>
                    <a data-bs-toggle="tab" href="/" className={!grid_view ? "active " : " "}
          onClick={setListView}><i className="fas fa-list"></i></a>
                </div>
            </div> */}
        </li>
        <li>
            <div className="showing-product-number text-right">
                <span>{`${filter_products.length} Product Available`}</span>
            </div> 
        </li>
        <li>
            <div className="short-by text-center">

                <form action="#">
                <label htmlFor="sort"></label>
                <select
                  name="sort"
                  id="sort"
                  className="nice-select"
                  onClick={sorting}>
                  <option value="lowest">Price - Low to High</option>
                  <option value="highest">Price - High to Low</option>
                  <option value="a-z">Price (a-z)</option>
                  <option value="z-a">Price (z-a)</option>
                </select>
              </form>
            </div> 
        </li>
    </ul>
    
    </>
  );
};

export default Sort;
