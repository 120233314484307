import { loadJS } from "./Helpers/GlobalFunctions";
import { useEffect, useState, useRef } from "react";
import { useCartContext } from "./context/cart_context";


const Register = () =>{
    const {userRegistration } = useCartContext();    
    const [error, setError] = useState([]);
    const [success_msg, setSuccess_msg] = useState([]);
    const ref = useRef(null);


    useEffect(() => {  
        loadJS("./js/plugins.js", false);
        loadJS("./js/main.js", false);
        
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [userInput, setUserInput] = useState({
        first_name: "",
        email: "",
        phone: "",
        password: "",
        confirmpassword: "",
        
    });
    const first_nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmpasswordRef = useRef(null);

    const handleInput = (e) => {
        e.persist();
        setUserInput({...userInput, [e.target.name]: e.target.value });
    }
    async function SubmitRegister()
    {
        if(!userInput.first_name || userInput.first_name==='')
        {
            alert("Please enter your full name.");
            first_nameRef.current.focus();
            return false;
        }
        if(!userInput.email || userInput.email==='')
        {
            alert("Please enter email address.");
            emailRef.current.focus();
            return false;
        }
        else
        {
            let validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

            if (!validEmail.test(userInput.email) ) 
            {
                alert("Please enter valid email address.");
                emailRef.current.focus();
                return false;
            }
        }
        if(!userInput.phone || userInput.phone==='')
        {
            alert("Please enter your full name.");
            phoneRef.current.focus();
            return false;
        }
        if(!userInput.password || userInput.password==='')
        {
            alert("Please enter password.");
            passwordRef.current.focus();
            return false;
        }
        if(!userInput.confirmpassword || userInput.confirmpassword==='')
        {
            alert("Please enter confirm password.");
            confirmpasswordRef.current.focus();
            return false;
        }
        if(userInput.password!==userInput.confirmpassword)
        {
            alert("Password and Confirm Password does not match.");
            confirmpasswordRef.current.focus();
            return false;
        }
        const regRes = await userRegistration(userInput);
        console.log('registerResponse:',regRes);
        if(regRes.status==='1' && regRes.result.user_id && regRes.result.user_id!==null)
        { 

            setSuccess_msg("You have been registered successfully.");
            setError("");
            window.scrollTo(0, 0);
        }
        else if(regRes.status==='0')
        {
            setError("Invalid Email address or password.");
            setSuccess_msg("");
        }
        else
        {
            setError("Something went wrong.");
            setSuccess_msg("");
        }
        return false;
    }

    useEffect(() =>  {
        if(ref.current )
        {
            setTimeout(async () =>  {
                await ref.current.click();  
            }, 10);
        }
    }, [ref]);

    return (
        <>

        <div className="ltn__checkout-area mb-120 text-left">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                            <h1 className="section-title">Register <br/>Your Account</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="account-login-inner">
                            <form action="#" className="ltn__form-box contact-form-box">
                               
                                <small className="text-success">{success_msg}</small>
                                <small className="text-danger">{error}</small>
                                <input type="text" onChange={handleInput} value={userInput.first_name} ref={first_nameRef} name="first_name" placeholder="Full Name*" />
                                <small className="text-danger">{error.first_name}</small>
                                <input type="text" onChange={handleInput} value={userInput.email} ref={emailRef} name="email" placeholder="Email*" />
                                <small className="text-danger">{error.email}</small>
                                <input type="text" onChange={handleInput} value={userInput.phone} ref={phoneRef} name="phone" placeholder="Phone*" />
                                <small className="text-danger">{error.phone}</small>
                                <input type="password" onChange={handleInput} value={userInput.password} ref={passwordRef} name="password" placeholder="Password*" />
                                <small className="text-danger">{error.password}</small>
                                <input type="password" onChange={handleInput} value={userInput.confirmpassword} ref={confirmpasswordRef} name="confirmpassword" placeholder="Confirm Password*" />
                                <small className="text-danger">{error.email}</small>
                                <div className="btn-wrapper">
                                    <button className="theme-btn-1 btn btn-block" onClick={() => SubmitRegister()} type="button">CREATE ACCOUNT</button>
                                </div>
                            </form>
                            <div className="by-agree text-center">
                                <div className="go-to-btn mt-50">
                                    <a href="login.html">ALREADY HAVE AN ACCOUNT ?</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        
        </>
    );
}; 
export default Register;