
const HeroSection = ({ myData }) => {


  return (
    
    <div className="ltn__slider-area ltn__slider-3  section-bg-1">
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
            <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60---" data-bs-bg="./images/slider/13.jpg">
                <div className="ltn__slide-item-inner  text-left">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 align-self-center">
                                <div className="slide-item-info">
                                    <div className="slide-item-info-inner ltn__slide-animation">
                                        <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-syringe"></i></span> 100% genuine Products</h6>
                                        <h1 className="slide-title animated ">Get upto 20% Discount <br/>Of Face Wash & Sunscreen Gel</h1>
                                        <div className="slide-brief animated">
                                            <p>Always have the pleasure of having a good skin.<br/>Let your skin dance with the beat of freshness.</p>
                                        </div>
                                        <div className="btn-wrapper animated">
                                            <a href="/products" className="theme-btn-1 btn ">Shop Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60---" data-bs-bg="./images/slider/11.jpg">
                <div className="ltn__slide-item-inner text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 align-self-center">
                                <div className="slide-item-info">
                                    <div className="slide-item-info-inner ltn__slide-animation">
                                        <div className="slide-video mb-50 d-none">
                                            <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
                                                <i className="fa fa-play"></i>
                                            </a>
                                        </div>
                                        <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-syringe"></i></span> 100% genuine Products</h6>
                                        <h1 className="slide-title animated ">Get 20% Discount <br/>Of N95 Mask</h1>
                                        <div className="slide-brief animated">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                        </div>
                                        <div className="btn-wrapper animated">
                                            <a href="shop.html" className="theme-btn-1 btn ">Shop Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60---" data-bs-bg="./images/slider/12.jpg">
                <div className="ltn__slide-item-inner  text-right text-end">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 align-self-center">
                                <div className="slide-item-info">
                                    <div className="slide-item-info-inner ltn__slide-animation">
                                        <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-syringe"></i></span> 100% genuine Products</h6>
                                        <h1 className="slide-title animated ">Get 20% Discount <br/>Of N95 Mask</h1>
                                        <div className="slide-brief animated">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                        </div>
                                        <div className="btn-wrapper animated">
                                            <a href="shop.html" className="theme-btn-1 btn ">Shop Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    </div>
    
  );
};


export default HeroSection;
