//import styled from "styled-components";
import FilterSection from "./components/FilterSection";
import ProductList from "./components/ProductList";
import Sort from "./components/Sort";
//import { useFilterContext } from "./context/filter_context";
import { useEffect } from "react";
//import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { loadJS } from "./Helpers/GlobalFunctions";

const Products = () => {

useEffect(() => {  
    loadJS("./js/plugins.js", false);
    loadJS("./js/main.js", false);
    });
    
useEffect(() => {
    window.scrollTo(0, 0)
    }, []);

  return (
    <>
    <Helmet>
        <title>RXKIN - Products</title>
    </Helmet>

    <div className="ltn__checkout-area mb-120 text-left">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 order-lg-2 mb-120">
                    <div className="ltn__shop-options">
                        <Sort />
                        
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="liton_product_grid">
                            <ProductList />
                        
                        </div>
                    </div>
                </div>
                <div className="col-lg-4  mb-120">
                    <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar">
                        <FilterSection />
                        

                    </aside>
                </div>
            </div>
        </div>
    </div>

      {/* <div className="container grid grid-filter-column">
        <div>
          <FilterSection />
        </div>

        <section className="product-view--sort">
          <div className="sort-filter">
            <Sort />
          </div>
          <div className="main-product">
            <ProductList />
          </div>
        </section>
      </div> */}
    
    </>
    
  );
};

export default Products;
