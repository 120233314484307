import React from "react";
import FormatPrice from "../Helpers/FormatPrice";

const CartItemForCheckout = ({ name, size, price, amount }) => {
  // const setDecrease = () => {
  //    amount > 1 ? setAmount(amount - 1) : setAmount(1);
  // };

  // const setIncrease = () => {
  //    amount < stock ? setAmount(amount + 1) : setAmount(stock);
  // };

  return (
    <tr>
        <td>{name} <span>({size})</span> <strong>× {amount}</strong></td>
        <td><FormatPrice price={price * amount} /></td>
    </tr>
  );
};

export default CartItemForCheckout;
