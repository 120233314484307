import React from "react";
import { NavLink } from "react-router-dom";
//import FormatPrice from "../Helpers/FormatPrice";
import AddToCartDetail from "./AddToCartDetail";

const Product4 = (curElem) => {
  const { slug, name, image, category, inventory } = curElem;
  //console.log(curElem);
  const stock = inventory && inventory[0].attr_stock;
 

  return (
    <>
    <li>
        <div className="top-rated-product-item clearfix">
            <div className="top-rated-product-img">
                <NavLink to={`/singleproduct/${slug}`} title={category} ><img src={image} alt={name}/></NavLink>
                
            </div>
            <div className="top-rated-product-info">
                <h6><a href="product-details.html"><NavLink to={`/singleproduct/${slug}`} >{name}</NavLink></a></h6>
                <div className="product-price">
                    {stock > 0 && <AddToCartDetail product={curElem} />}
                </div>
            </div>
        </div>
    </li>
    </>
  );
};

export default Product4;
