import React from "react";
import FormatPrice from "../Helpers/FormatPrice";
import CartAmountToggle from "./CartAmountToggle";
import { FaTrash } from "react-icons/fa";
import { useCartContext } from "../context/cart_context";

const CartItem = ({ id, name, image, size, price, amount }) => {
  const { removeItem, setDecrease, setIncrement } = useCartContext();
  // const setDecrease = () => {
  //    amount > 1 ? setAmount(amount - 1) : setAmount(1);
  // };

  // const setIncrease = () => {
  //    amount < stock ? setAmount(amount + 1) : setAmount(stock);
  // };

  return (
    <tr>
      <td className="cart-product-remove"><FaTrash className="remove_icon" onClick={() => removeItem(id)} /></td>
      <td className="cart-product-image">
          <img src={image} alt={id} />
      </td>
      <td className="cart-product-info">
          <h4>{name}</h4>
          <p>Size:{size}</p>
      </td>
      <td className="cart-product-price"><FormatPrice price={price} /></td>
      <td className="cart-product-quantity">
      <CartAmountToggle
        amount={amount}
        setDecrease={() => setDecrease(id)}
        setIncrease={() => setIncrement(id)}
      />
      </td>
      <td className="cart-product-subtotal"><FormatPrice price={price * amount} /></td>
  </tr>


    // <div className="cart_heading grid grid-five-column">
    //   <div className="cart-image--name">
    //     <div>
    //       <figure>
    //         <img src={image} alt={id} />
    //       </figure>
    //     </div>
    //     <div>
    //       <p>{name}</p>
    //       <div className="color-div">
    //         <p>color:</p>
    //         <div
    //           className="color-style"
    //           style={{ backgroundColor: size, color: size }}></div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* price   */}
    //   <div className="cart-hide">
    //     <p>
    //       <FormatPrice price={price} />
    //     </p>
    //   </div>

    //   {/* Quantity  */}
    //   <CartAmountToggle
    //     amount={amount}
    //     setDecrease={setDecrease}
    //     setIncrease={setIncrease}
    //   />

    //   {/* //Subtotal */}
    //   <div className="cart-hide">
    //     <p>
    //       <FormatPrice price={price * amount} />
    //     </p>
    //   </div>

    //   <div>
    //     <FaTrash className="remove_icon" onClick={() => removeItem(id)} />
    //   </div>
    // </div>
  );
};

export default CartItem;
