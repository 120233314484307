const HomeAboutUs =()=>{

    return (

    <div className="ltn__about-us-area pt-25 pb-120 ">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 align-self-center">
                    <div className="about-us-img-wrap about-img-left">
                        <img src="./images/others/9.png" alt="About Us"/>
                    </div>
                </div>
                <div className="col-lg-6 align-self-center">
                    <div className="about-us-info-wrap">
                        <div className="section-title-area ltn__section-title-2--- mb-30">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color d-none">About Us</h6>
                            <h1 className="section-title">Your faithful partners Cosmetics Goods</h1>
                            <p>We at RXKIN provides skin & hare care products at wholesale price and our mission is to bring our products at every doorstep.  </p>
                            <p>RXKIN is India's largest online pharmacy.. For more than ten years, we have been offering genuine quality healthcare products. Everything you require in terms of healthcare is guaranteed to be available on our platform.</p>
                        </div>
                        <ul className="ltn__list-item-1 ltn__list-item-1-before--- clearfix">
                            <li><i className="fas fa-check-square"></i> Look through our wide range & variety of products</li>
                            <li><i className="fas fa-check-square"></i> Add products to your cart and make the payment.</li>
                            <li><i className="fas fa-check-square"></i> Your order will be on its way to reach you.</li>
                        </ul>
                        <div className="about-author-info-2 border-top mt-30 pt-20">
                            <ul>
                                <li>
                                    <div className="about-author-info-2-brief  d-flex">
                                        <div className="author-img">
                                        <img src="./images/blog/author.jpg" alt="author"/>
                                        </div>
                                        <div className="author-name-designation">
                                            <h4 className="mb-0">Customer Care</h4>
                                            <small>Medical Specialist</small>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="about-author-info-2-contact  d-flex">
                                        <div className="about-contact-icon d-flex align-self-center mr-10">
                                            <i className="icon-call"></i>
                                        </div>
                                        <div className="about-author-info-2-contact-info">
                                            <small>Get Support</small>
                                            <h6 className="mb-0">+91-8368445108</h6>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

        
    );
};

export default HomeAboutUs;