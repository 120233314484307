import { useEffect } from "react";
import { loadJS } from "./Helpers/GlobalFunctions";

const Contact = () => {

  useEffect(() => {  
    loadJS("./js/plugins.js", false);
    loadJS("./js/main.js", false);
  });

  return (
    <>
        <div className="ltn__checkout-area mb-120 text-left contact-bg" data-bs-bg="/images/bg/14.jpg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__breadcrumb-inner">
                            <h1 className="page-title">Contact Us</h1>
                            <div className="ltn__breadcrumb-list">
                                <ul>
                                    <li><a href="/"><span className="ltn__secondary-color"><i className="fas fa-home"></i></span> Home</a></li>
                                    <li>Contact</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="ltn__contact-address-area mb-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                            <div className="ltn__contact-address-icon">
                                <img src="/images/icons/10.png" alt="email address"/>
                            </div>
                            <h3>Email Address</h3>
                            <p>info@rxkin.com</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                            <div className="ltn__contact-address-icon">
                                <img src="/images/icons/11.png" alt="phone number"/>
                            </div>
                            <h3>Phone Number</h3>
                            <p>+91-8368445108</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                            <div className="ltn__contact-address-icon">
                                <img src="/images/icons/12.png" alt="address"/>
                            </div>
                            <h3>Office Address</h3>
                            <p>Ram Bagan - Ramkirishna Colony, <br/>P.O. Sear Sol Rajbari, <br/>Rajniganj - Burdawan, W. Bengal</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="google-map mb-120">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14621.26500993785!2d87.10441961173805!3d23.62884269343483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f7115c2a7f6207%3A0xeb3257f4553c5724!2sSearsole%20Rajbari%20More!5e0!3m2!1sen!2sin!4v1722357647179!5m2!1sen!2sin" width="100%" height="100%" frameborder="0" allowfullscreen="" title="RXKIN Location on Map" aria-hidden="false" tabindex="0"></iframe>

        </div>  
        </>
  );
};

export default Contact;
