import React from "react";
import Product3 from "./Product3";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const ProdPerRow = 20;

const GridView = ({ products }) => {

  const [next, setNext] = useState(ProdPerRow);
  const handleMoreProd = () => {
    setNext(next + ProdPerRow);
  };

  return (
    <div className="ltn__product-tab-content-inner ltn__product-grid-view product_list">
      <div className="row">
        {products?.slice(0, next)?.map((curElem) => {
          return <Product3 key={curElem.id} {...curElem} />;
        })}

        {next < products?.length && (
          <NavLink
            className="theme-btn-1 btn "
            onClick={handleMoreProd}
          >
            Load more
          </NavLink>
        )}
      </div>
    </div>
  );
};


export default GridView;
