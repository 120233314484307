import { loadJS } from "./Helpers/GlobalFunctions";
import { useEffect, useState, useRef } from "react";
import { useCartContext } from "./context/cart_context";
import {useNavigate } from "react-router-dom";
import OrderItem from "./components/OrderItem";


const Myaccount = () =>{
    const {current_user, checkLogin, customer_orders } = useCartContext();    
    const [error, setError] = useState([]);
    const ref = useRef(null);
    const navigate = useNavigate();
    const ordersData = customer_orders.orderResponse;

    console.log('customer_orders:',ordersData);

    useEffect(() => {  
        loadJS("./js/plugins.js", false);
        loadJS("./js/main.js", false);
        
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [userInput, setUserInput] = useState({
        email: "",
        password: "",
        
    });
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const handleInput = (e) => {
        e.persist();
        setUserInput({...userInput, [e.target.name]: e.target.value });
    }
    async function SubmitLogin()
    {
        if(!userInput.email || userInput.email==='')
        {
            alert("Please enter email address.");
            emailRef.current.focus();
            return false;
        }
        else
        {
            let validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

            if (!validEmail.test(userInput.email) ) 
            {
                alert("Please enter valid email address.");
                emailRef.current.focus();
                return false;
            }
        }

        if(!userInput.password || userInput.password==='')
        {
            alert("Please enter password.");
            passwordRef.current.focus();
            return false;
        }
        const loginResponse = await checkLogin(userInput.email,userInput.password);
        console.log('loginrespoinse:',loginResponse);
        if(loginResponse.userid && loginResponse.userid!==null)
        { 
            navigate('/myaccount');
        }
        else
        {
            setError("Invalid Email address or password.");
        }
        return false;
    }

    useEffect(() =>  {
        if(ref.current )
        {
            setTimeout(async () =>  {
                await ref.current.click();  
            }, 10);
        }
    }, [ref]);

    return (
        <>
        <div className="ltn__checkout-area mb-120 text-left">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__product-tab-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="ltn__tab-menu-list mb-50">
                                            <div className="nav">
                                                <a className="active show" data-bs-toggle="tab" href="#liton_tab_1_1">Dashboard <i className="fas fa-home"></i></a>
                                                <a data-bs-toggle="tab" href="#liton_tab_1_2">Orders <i className="fas fa-file-alt"></i></a>
                                                {/* <a data-bs-toggle="tab" href="#liton_tab_1_4">address <i className="fas fa-map-marker-alt"></i></a>
                                                <a data-bs-toggle="tab" href="#liton_tab_1_5">Account Details <i className="fas fa-user"></i></a> */}
                                                <a href="/logout">Logout <i className="fas fa-sign-out-alt"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="tab-content">
                                            <div className="tab-pane fade active show" id="liton_tab_1_1">
                                                <div className="ltn__myaccount-tab-content-inner">
                                                    <p>Hello <strong>{current_user.userName}</strong>,</p>
                                                    <p>From your account dashboard you can view your <span>recent orders</span> and <span>edit your password and account details</span>.</p>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="liton_tab_1_2">
                                                <div className="ltn__myaccount-tab-content-inner">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Order Id</th>
                                                                    <th>Date</th>
                                                                    <th>Status</th>
                                                                    <th>Total</th>
                                                                    <th>Payment Type</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {ordersData && ordersData.map((curElem) => {
                                                                return <OrderItem key={curElem.id} {...curElem} />;
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="tab-pane fade" id="liton_tab_1_4">
                                                <div className="ltn__myaccount-tab-content-inner">
                                                    <p>The following addresses will be used on the checkout page by default.</p>
                                                    <div className="row">
                                                        <div className="col-md-6 col-12 learts-mb-30">
                                                            <h4>Billing Address <small><a href="/">edit</a></small></h4>
                                                            <address>
                                                                <p><strong>Alex Tuntuni</strong></p>
                                                                <p>1355 Market St, Suite 900 <br/>
                                                                    San Francisco, CA 94103</p>
                                                                <p>Mobile: (123) 456-7890</p>
                                                            </address>
                                                        </div>
                                                        <div className="col-md-6 col-12 learts-mb-30">
                                                            <h4>Shipping Address <small><a href="/">edit</a></small></h4>
                                                            <address>
                                                                <p><strong>Alex Tuntuni</strong></p>
                                                                <p>1355 Market St, Suite 900 <br/>
                                                                    San Francisco, CA 94103</p>
                                                                <p>Mobile: (123) 456-7890</p>
                                                            </address>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="liton_tab_1_5">
                                                <div className="ltn__myaccount-tab-content-inner">
                                                    <p>The following addresses will be used on the checkout page by default.</p>
                                                    <div className="ltn__form-box">
                                                        <form action="#">
                                                            <div className="row mb-50">
                                                                <div className="col-md-6">
                                                                    <label>First name:</label>
                                                                    <input type="text" name="ltn__name" />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Last name:</label>
                                                                    <input type="text" name="ltn__lastname" />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Display Name:</label>
                                                                    <input type="text" name="ltn__lastname" placeholder="Ethan" />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Display Email:</label>
                                                                    <input type="email" name="ltn__lastname" placeholder="example@example.com" />
                                                                </div>
                                                            </div>
                                                            <fieldset>
                                                                <legend>Password change</legend>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <label>Current password (leave blank to leave unchanged):</label>
                                                                        <input type="password" name="ltn__name" />
                                                                        <label>New password (leave blank to leave unchanged):</label>
                                                                        <input type="password" name="ltn__lastname" />
                                                                        <label>Confirm new password:</label>
                                                                        <input type="password" name="ltn__lastname" />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                            <div className="btn-wrapper">
                                                                <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Save Changes</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        
        </>
    );
}; 
export default Myaccount;