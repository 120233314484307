//import FeatureProduct from "./components/FeatureProduct";
import { useEffect } from "react";
import { loadJS } from "./Helpers/GlobalFunctions";
import HeroSection from "./components/HeroSection";
import HomeCategory from "./components/HomeCategory";
import HomeAboutUs from "./components/HomeAboutUs";
import HomePromoOffers from "./components/HomePromoOffers";
import HomeFeaturedProd from "./components/HomeFeaturedProd";
import FeaturesArea from "./components/FeaturesArea";
import HomeBlogs from "./components/HomeBlogs";
//import Services from "./components/Services";
//import Trusted from "./components/Trusted";
import HomeLatestProducts from "./components/HomeLatestProducts";
import BrandsSlider from "./components/BrandsSlider";

const Home = () => {
  const data = {
    name: "Deep Pharma",
  };
  
  useEffect(() => {  
    loadJS("./js/plugins.js", false);
    loadJS("./js/main.js", false);
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <HeroSection myData={data} />
      <HomeFeaturedProd/>
      <HomeCategory />
      <HomeAboutUs />
      
      <HomePromoOffers/>
      <HomeLatestProducts/>
      <FeaturesArea/>
      <HomeBlogs/>
      <BrandsSlider/>

      
    </>
  );
};

export default Home;
