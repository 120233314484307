import { useProductContext } from "../context/productcontex";
import Product4 from "./Product4";

const TopRatedProducts = () =>{

    const { isLoading, featureProducts } = useProductContext();
    
    if (isLoading) {
        return <div> ......Loading </div>;
    }

    return (
        <div className="col-lg-4">
                    <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar">

                        <div className="widget ltn__top-rated-product-widget">
                            <h4 className="ltn__widget-title ltn__widget-title-border">Top Rated Product</h4>
                            <ul>
                                {featureProducts && featureProducts.slice(0, 3).map((curElem) => {
                                    return <Product4 key={curElem.id} {...curElem} />;
                                })}
                                
                            </ul>
                        </div>

                        {/* <div className="widget ltn__banner-widget">
                            <a href="/products"><img src="../images/banner/2.jpg" alt="#"/></a>
                        </div> */}
                    </aside>
                </div>
    );
}
export default TopRatedProducts;
