import { useEffect } from "react";
import { loadJS } from "./Helpers/GlobalFunctions";

const RefundPolicy = () => {

  useEffect(() => {  
    loadJS("./js/plugins.js", false);
    loadJS("./js/main.js", false);
  });

  return (
    <>

        <div className="ltn__checkout-area mb-120 text-left ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="ltn__section-title-2--- mb-30">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color d-none">About Us</h6>
                                <h1 className="section-title">RETURNS AND REFUND POLICY</h1>


<p>Thank you for shopping at RXKIN! If you are not satisfied with your purchase, we are here to help! Products are eligible for return and refunds, failing to do so will lead to the product getting spoilt and your claim will be null and void.</p>

<p>All refunds and returns are on sole discretion of RXKIN upon its verification of the claim for refund and the returned product(s).</p>

<p><strong>RETURNS</strong></p>

<p>You have 3 calendar days to return an item from the date you received it. To be eligible for a return, your item must be:</p>

<ol>
<li>Unused and in the same condition that you received it;</li>
<li>In the original packaging; and</li>
<li>Accompanied by the receipt or email invoice.</li>
</ol>

<p>You need to keep the products ready so we can get them collected smoothly. We will call and let you know when the next pick up will take place.</p>

<p>&nbsp;</p>

<p><strong>REFUNDS</strong></p>

<p>Once we receive your item, we will inspect it and notify you that we have received your returned item.We will notify you on the status of your refund after inspecting the item.&nbsp;If your return is approved, we will initiate a refund to your credit card (or original method of payment) within 15 days and you will receive the credit within a month, depending on your card issuer's policies. Refunds and returns are deemed valid only if there's a problem with the product.</p>

<p>&nbsp;</p>

<p><strong>CONTACT US</strong></p>

<p>If you have any questions on how to return your item to us, contact us on +91-8368445108 or email us on info@rxkin.com</p>


                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  );
};

export default RefundPolicy;
