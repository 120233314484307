import { useEffect } from "react";
import { loadJS } from "./Helpers/GlobalFunctions";

const CancellationPolicy = () => {

  useEffect(() => {  
    loadJS("./js/plugins.js", false);
    loadJS("./js/main.js", false);
  });

  return (
    <>

        <div className="ltn__checkout-area mb-120 text-left ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="ltn__section-title-2--- mb-30">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color d-none">About Us</h6>
                                <h1 className="section-title">Cancellation Policy</h1>

<p><strong>Cancellation Policy</strong><br/>
<br/>
Any changes to the order would be entertained with monetary adjustments if requested before 24hrs of the actual delivery.<br/>
<br/>
Changes after that will not be entertained and order would not be canceled.<br />
&nbsp;</p>

<p><strong>Shipping Policy</strong></p>

<p>We have a shipping policy that we dispatch goods within 48 to 72 hours after receiving the order.</p>

                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  );
};

export default CancellationPolicy;
