import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { useProductContext } from "./context/productcontex";
//import PageNavigation from "./components/PageNavigation";
import ProdImages from "./components/ProdImages";
//import { Container } from "./styles/Container";
//import FormatPrice from "./Helpers/FormatPrice";
//import { MdSecurity } from "react-icons/md";
//import { TbTruckDelivery, TbReplace } from "react-icons/tb";
import Star from "./components/Star";
import AddToCartDetail from "./components/AddToCartDetail";
import { loadJS } from "./Helpers/GlobalFunctions";
import TopRatedProducts from "./components/TopRatedProducts";
import { Helmet } from "react-helmet";



const API = "https://shop.rxkin.com/apis/single_product.php";

const SingleProduct = () => {
  const { getSingleProduct, isSingleLoading, singleProduct } = useProductContext();

  const { slug } = useParams();
  //console.log("product data:"+JSON.stringify(singleProduct));
  const {
  
    name,
   
    category,
    description,
    meta_title,
    meta_description,
    meta_keywords,
    stars,
    reviews,
    images,
    inventory,
    
  } = singleProduct;

  const stock = inventory && inventory[0].attr_stock;

  useEffect(() => {
    getSingleProduct(`${API}?slug=${slug}`);
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
useEffect(() => {
    loadJS("../js/plugins.js", false);
    loadJS("../js/main.js", false);
});  

useEffect(() => {
    window.scrollTo(0, 0)
}, [])

  if (isSingleLoading) {
    return (
    <div className="ltn__shop-details-area pb-85">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="page_loading"><img src="/images/loading3.gif" alt="Loading" /></div>
                </div>
            </div>
        </div>
    </div>    
    )
  }

  

  return (
    <>
    <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
        <meta name="keywords" content={meta_keywords} />
        
    </Helmet>
    <div className="ltn__shop-details-area pb-85">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-12">
                    <div className="ltn__shop-details-inner mb-2">
                        <div className="row">
                            <div className="col-md-6 pl-0 mt-2">
                                <h3 className="prod_title animated fadeIn mobile">{name}</h3>
                                <div className="ltn__shop-details-img-gallery">
                                    <ProdImages imgs={images} />
                                    
                                </div>
                            </div>
                            <div className="col-md-6 pl-0 pr-0 mt-2">
                                <div className="modal-product-info shop-details-info pl-0">
                                    <h3 className="desktop">{name}</h3>
                                    <div class="row">
                                        <div className="col-xs-6 product-ratting ">
                                            <Star stars={stars} reviews={reviews} />
                                            
                                        </div>
                                        <div className="col-xs-6 modal-product-meta ltn__product-details-menu-1">
                                            <ul>
                                                <li>
                                                    <strong>Categories:</strong> 
                                                    <span>
                                                        <a href="/">{category}</a>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {stock > 0 && <AddToCartDetail product={singleProduct} />}
                                    {/* <AddToCartDetail product={singleProduct}  /> */}
                                    {/* {inventory.map((curElm) => {
                                    
                                        
                                       
                                            return (
                                            <>
                                        <div className="product-price">
                                            <span><FormatPrice price={curElm.attr_odprice} /></span>
                                            <del><FormatPrice price={curElm.attr_oaprice} /></del>
                                        </div>
                                        
                                        <div className="ltn__product-details-menu-2">
                                            {curElm.attr_stock > 0 && <AddToCartDetail product={singleProduct}  />}
                                        </div>
                                        </>
                                        );
                                       
                                        
                                        
                                    })} */}
                                    
                                    
                                    

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ltn__shop-details-tab-inner ltn__shop-details-tab-inner-2">
                        <div className="ltn__shop-details-tab-menu">
                            <div className="nav">
                                <a className="active show" data-bs-toggle="tab" href="#liton_tab_details_1_1">Description</a>
                                {/* <a data-bs-toggle="tab" href="#liton_tab_details_1_2" className="">Reviews</a> */}
                            </div>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade active show" id="liton_tab_details_1_1">
                                <div className="ltn__shop-details-tab-content-inner">
                                    {description}
                                </div>
                            </div>
                            {/* <div className="tab-pane fade" id="liton_tab_details_1_2">
                                <div className="ltn__shop-details-tab-content-inner">
                                    <h4 className="title-2">Customer Reviews</h4>
                                    <div className="product-ratting">
                                    <Star stars={stars} reviews={reviews} />
                                    </div>
                                    <hr/>

                                    <div className="ltn__comment-area mb-30">
                                        <div className="ltn__comment-inner">
                                            <ul>
                                                <li>
                                                    <div className="ltn__comment-item clearfix">
                                                        <div className="ltn__commenter-img">
                                                            <img src="../images/testimonial/1.jpg" alt="Image16"/>
                                                        </div>
                                                        <div className="ltn__commenter-comment">
                                                            <h6><a href="/">Adam Smit</a></h6>
                                                            <div className="product-ratting">
                                                                <ul>
                                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                                                </ul>
                                                            </div>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, omnis fugit corporis iste magnam ratione.</p>
                                                            <span className="ltn__comment-reply-btn">September 3, 2020</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="ltn__comment-item clearfix">
                                                        <div className="ltn__commenter-img">
                                                            <img src="../images/testimonial/3.jpg" alt="Image17"/>
                                                        </div>
                                                        <div className="ltn__commenter-comment">
                                                            <h6><a href="/">Adam Smit</a></h6>
                                                            <div className="product-ratting">
                                                                <ul>
                                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                                                </ul>
                                                            </div>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, omnis fugit corporis iste magnam ratione.</p>
                                                            <span className="ltn__comment-reply-btn">September 2, 2020</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="ltn__comment-item clearfix">
                                                        <div className="ltn__commenter-img">
                                                            <img src="../images/testimonial/2.jpg" alt="Image1"/>
                                                        </div>
                                                        <div className="ltn__commenter-comment">
                                                            <h6><a href="/">Adam Smit</a></h6>
                                                            <div className="product-ratting">
                                                                <ul>
                                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                                                </ul>
                                                            </div>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, omnis fugit corporis iste magnam ratione.</p>
                                                            <span className="ltn__comment-reply-btn">September 2, 2020</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="ltn__comment-reply-area ltn__form-box mb-30">
                                        <form action="#">
                                            <h4 className="title-2">Add a Review</h4>
                                            <div className="mb-30">
                                                <div className="add-a-review">
                                                    <h6>Your Ratings:</h6>
                                                    <div className="product-ratting">
                                                        <ul>
                                                            <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                            <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                            <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                            <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                                            <li><a href="/"><i className="far fa-star"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-item input-item-textarea ltn__custom-icon">
                                                <textarea placeholder="Type your comments...."></textarea>
                                            </div>
                                            <div className="input-item input-item-name ltn__custom-icon">
                                                <input type="text" placeholder="Type your name...."/>
                                            </div>
                                            <div className="input-item input-item-email ltn__custom-icon">
                                                <input type="email" placeholder="Type your email...."/>
                                            </div>
                                            <div className="input-item input-item-website ltn__custom-icon">
                                                <input type="text" name="website" placeholder="Type your website...."/>
                                            </div>
                                            <label className="mb-0"><input type="checkbox" name="agree"/> Save my name, email, and website in this browser for the next time I comment.</label>
                                            <div className="btn-wrapper">
                                                <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <hr/>
                        {/* <div className="ltn__social-media">
                            <ul>
                                <li>Share:</li>
                                <li><a href="/" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="/" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="/" title="Linkedin"><i className="fab fa-linkedin"></i></a></li>
                                <li><a href="/" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                                
                            </ul>
                        </div> */}
                        <hr/>
                        <div className="ltn__safe-checkout">
                            <h5>Guaranteed Safe Checkout</h5>
                            <img src="../images/icons/payment-2.png" alt="Payment Image21"/>
                        </div>
                    </div>

                </div>
                <TopRatedProducts/>
            </div>
        </div>
    </div>
{/* 
    <div className="ltn__product-slider-area ltn__product-gutter pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title-area ltn__section-title-2">
                        <h4 className="title-2">Related Products<span>.</span></h4>
                    </div>
                </div>
            </div>
            <div className="row ltn__related-product-slider-one-active slick-arrow-1 product_list">

                <div className="col-lg-12">
                    <div className="ltn__product-item ltn__product-item-3 text-center">
                        <div className="product-img">
                            <a href="product-details.html"><img src="../images/product/7.png" alt="#"/></a>
                            <div className="product-badge">
                                <ul>
                                    <li className="sale-badge">New</li>
                                </ul>
                            </div>
                            
                        </div>
                        <div className="product-info">
                            <div className="product-ratting">
                                <ul>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                </ul>
                            </div>
                            <h2 className="product-title"><a href="product-details.html">Digital Stethoscope</a></h2>
                            <div className="product-price">
                                <span>$149.00</span>
                                <del>$162.00</del>
                            </div>
                            <a href="/" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal" className="theme-btn-1 btn ">
                                <i className="fas fa-shopping-cart"></i>
                                <span>ADD TO CART</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="ltn__product-item ltn__product-item-3 text-center">
                        <div className="product-img">
                            <a href="product-details.html"><img src="../images/product/8.png" alt="#"/></a>
                            <div className="product-badge">
                                <ul>
                                    <li className="sale-badge">New</li>
                                </ul>
                            </div>
                          
                        </div>
                        <div className="product-info">
                            <div className="product-ratting">
                                <ul>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                </ul>
                            </div>
                            <h2 className="product-title"><a href="product-details.html">Thermometer Gun</a></h2>
                            <div className="product-price">
                                <span>$62.00</span>
                                <del>$85.00</del>
                            </div>
                            <a href="/" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal" className="theme-btn-1 btn ">
                                <i className="fas fa-shopping-cart"></i>
                                <span>ADD TO CART</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="ltn__product-item ltn__product-item-3 text-center">
                        <div className="product-img">
                            <a href="product-details.html"><img src="../images/product/9.png" alt="#"/></a>
                            <div className="product-badge">
                                <ul>
                                    <li className="sale-badge">New</li>
                                </ul>
                            </div>
                         
                        </div>
                        <div className="product-info">
                            <div className="product-ratting">
                                <ul>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                </ul>
                            </div>
                            <h2 className="product-title"><a href="product-details.html">Cosmetic Containers</a></h2>
                            <div className="product-price">
                                <span>$75.00</span>
                                <del>$92.00</del>
                            </div>
                            <a href="/" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal" className="theme-btn-1 btn ">
                                <i className="fas fa-shopping-cart"></i>
                                <span>ADD TO CART</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="ltn__product-item ltn__product-item-3 text-center">
                        <div className="product-img">
                            <a href="product-details.html"><img src="../images/product/10.png" alt="#"/></a>
                            <div className="product-badge">
                                <ul>
                                    <li className="sale-badge">New</li>
                                </ul>
                            </div>
                         
                        </div>
                        <div className="product-info">
                            <div className="product-ratting">
                                <ul>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                </ul>
                            </div>
                            <h2 className="product-title"><a href="product-details.html">Cosmetic Containers</a></h2>
                            <div className="product-price">
                                <span>$78.00</span>
                                <del>$85.00</del>
                            </div>
                            <a href="/" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal" className="theme-btn-1 btn ">
                                <i className="fas fa-shopping-cart"></i>
                                <span>ADD TO CART</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="ltn__product-item ltn__product-item-3 text-center">
                        <div className="product-img">
                            <a href="product-details.html"><img src="../images/product/5.png" alt="#"/></a>
                            <div className="product-badge">
                                <ul>
                                    <li className="sale-badge">New</li>
                                </ul>
                            </div>
                           
                        </div>
                        <div className="product-info">
                            <div className="product-ratting">
                                <ul>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                </ul>
                            </div>
                            <h2 className="product-title"><a href="product-details.html">Blue Hand Gloves</a></h2>
                            <div className="product-price">
                                <span>$150.00</span>
                                <del>$180.00</del>
                            </div>
                            <a href="/" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal" className="theme-btn-1 btn ">
                                <i className="fas fa-shopping-cart"></i>
                                <span>ADD TO CART</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="ltn__product-item ltn__product-item-3 text-center">
                        <div className="product-img">
                            <a href="product-details.html"><img src="../images/product/6.png" alt="#"/></a>
                            <div className="product-badge">
                                <ul>
                                    <li className="sale-badge">New</li>
                                </ul>
                            </div>
                   
                        </div>
                        <div className="product-info">
                            <div className="product-ratting">
                                <ul>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                </ul>
                            </div>
                            <h2 className="product-title"><a href="product-details.html">Thermometer Gun</a></h2>
                            <div className="product-price">
                                <span>$150.00</span>
                                <del>$180.00</del>
                            </div>
                            <a href="/" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal" className="theme-btn-1 btn ">
                                <i className="fas fa-shopping-cart"></i>
                                <span>ADD TO CART</span>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div> */}




  
    </>
  );
};

export default SingleProduct;
