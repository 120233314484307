import { useEffect } from "react";
import { loadJS } from "./Helpers/GlobalFunctions";

const Terms = () => {

  useEffect(() => {  
    loadJS("./js/plugins.js", false);
    loadJS("./js/main.js", false);
  });

  return (
    <>

        <div className="ltn__checkout-area mb-120 text-left ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="ltn__section-title-2--- mb-30">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color d-none">About Us</h6>
                                <h1 className="section-title">Terms & Conditions</h1>
                                
<h4>Terms of Service</h4>
<p>
Please read these terms of use carefully. By accessing or using this internet based platform, you agree to be bound by the terms described herein and all terms incorporated by reference. If you do not agree to all of these terms, please do not use this internet based platform. By accessing these web site pages (collectively, the "Site") or other digital services (together with the Site, the "Services") belonging to or operated by RXKIN and its affiliates (collectively, "RXKIN," "we," "us," or "our"), including the https://www.rxkin.com site, you agree to the following terms of use (the "Terms"). If you do not agree with any part of the following Terms, you are not allowed to access or use the Site or Services.</p>

<h4>Amendments</h4>
<p>
These Terms may be modified from time to time at the sole discretion of RXKIN without advance notice to you. RXKIN shall notify you of such changes by posting the modified Terms on the Site. Accessing or using this Site or any Services, means that you acknowledge that you have read, understood, accepted and agreed to be legally bound by the most recent version of the Terms.</p>


<h4>Health Information</h4>

    <p>This Site or the Services may contain information related to various health and medical conditions and products that may be used as part of their treatment. Such information is not meant to be a substitute for the advice provided by a physician or other medical professional. You should not use the information contained on this Site or the Services for diagnosing a health problem or disease. You should always consult a medical professional, such as a physician or a pharmacist, to determine what courses of treatment, if any, may be appropriate for you or if you have any questions regarding a medical condition or stopping, starting or modifying any treatment.</p>
    <p>The Site and Services may provide links to independent third-party service providers, including third parties that may facilitate the provision of or provide telemedicine services or access to health care providers (each, a "Third-Party Provider" and collectively, "Third-Party Providers"). Such Third-Party Providers are not agents, employees or affiliates of RXKIN. Any information or advice received from a Third-Party Provider comes from the Third-Party Provider alone and not from RXKIN. RXKIN is not responsible for, and disclaims all liability for telemedicine services, which are provided solely by Third-Party Providers in accordance with the applicable Third-Party Provider's policies and terms, and RXKIN shall not be liable for any information or advice obtained from a Third-Party Provider.</p>
    <p>We would like to assure you that all products are 100% authentic and sourced from authorized vendors. We take great care in ensuring that the products meet the highest standards of quality & safety. Additionally, all of the products are properly labeled with batch numbers and detailed information. This allows us to trace the origins and quality of each product, ensuring that our customers receive only the best.</p>

<h4>Intellectual Property</h4>

<p>The content of the Site and Services, including all information, materials, text, documents, images, audio, video, computer code, software and any other content is the property of RXKIN and/or applicable third parties and is protected by applicable intellectual property laws. Any rights not expressly granted are reserved.</p>
<p>You are permitted to view the RXKIN Site and Services on your computer or mobile device and print copies of extracts for your personal use only. Except as expressly provided above, you may not copy, display, download, distribute, modify, reproduce, republish, or transfer any part or all of the contents of the Site and Services or create any derivative work based on such content without the prior written permission of RXKIN.</p>
<p>The use of this Site and its content is permitted for private, non-commercial use only. Unless consented to, in writing by RXKIN, redistribution of all or part of this Site or the Services for commercial use is strictly prohibited.</p>
<p>Nothing contained in these Terms or through your access and/or use of the Services shall be construed as conferring any license to you under any patent, trademark copyright, trade secret or other intellectual property right of RXKIN or any third party.</p>

<p>These Terms of Use is published in compliance of, and is governed by the provisions of Indian laws, including but limited to:</p>

<p>The Indian Contract Act, 1872 ("Contract Act");
    The (Indian) Information Technology Act, 2000 ("IT Act") and the rules, regulations, guidelines and clarifications framed thereunder, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011, and the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 ("IG Guidelines");
    The Drugs and Cosmetic Act, 1940 ("Drugs Act"), read with the Drugs and Cosmetics Rules, 1945 ("Drugs Rules");
    The Drugs and Magic Remedies (Objectionable Advertisements) Act, 1954 ("Drugs and Magic Act");
    The Indian Medical Council Act, 1956 read with the Indian Medical Council Rules, 1957;
    Pharmacy Act, 1948 ("Pharmacy Act") and
    The Consumer Protection Act, 1986.</p>


                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  );
};

export default Terms;
