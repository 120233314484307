const QuickView =()=>{
    return (

    <div className="ltn__modal-area ltn__quick-view-modal-area">
        <div className="modal fade" id="quick_view_modal" >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="ltn__quick-view-modal-inner">
                            <div className="modal-product-item">
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="modal-product-img">
                                            <img src="../images/product/4.png" alt="#"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="modal-product-info">
                                            <div className="product-ratting">
                                                <ul>
                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                    <li><a href="/"><i className="fas fa-star"></i></a></li>
                                                    <li><a href="/"><i className="fas fa-star-half-alt"></i></a></li>
                                                    <li><a href="/"><i className="far fa-star"></i></a></li>
                                                    <li className="review-total"> <a href="/"> ( 95 Reviews )</a></li>
                                                </ul>
                                            </div>
                                            <h3><a href="product-details.html">Digital Stethoscope</a></h3>
                                            <div className="product-price">
                                                <span>$15.00</span>
                                                <del>$25.00</del>
                                            </div>
                                            <hr/>
                                            <div className="modal-product-brief">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos repellendus repudiandae incidunt quidem pariatur expedita, quo quis modi tempore non.</p>
                                            </div>
                                            <div className="modal-product-meta ltn__product-details-menu-1 d-none">
                                                <ul>
                                                    <li>
                                                        <strong>Categories:</strong> 
                                                        <span>
                                                            <a href="/">Parts</a>
                                                            <a href="/">Car</a>
                                                            <a href="/">Seat</a>
                                                            <a href="/">Cover</a>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="ltn__product-details-menu-2 d-none">
                                                <ul>
                                                    <li>
                                                        <div className="cart-plus-minus">
                                                            <input type="text" placeholder="02" name="qtybutton" className="cart-plus-minus-box"/>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a href="/" className="theme-btn-1 btn btn-effect-1" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal">
                                                            <i className="fas fa-shopping-cart"></i>
                                                            <span>ADD TO CART</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <hr/> 
                                            <div className="ltn__product-details-menu-3">
                                                <ul>
                                                    <li>
                                                        <a href="/" className="" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
                                                            <i className="far fa-heart"></i>
                                                            <span>Add to Wishlist</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/" className="" title="Compare" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
                                                            <i className="fas fa-exchange-alt"></i>
                                                            <span>Compare</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <hr/>
                                            <div className="ltn__social-media">
                                                <ul>
                                                    <li>Share:</li>
                                                    <li><a href="/" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="/" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="/" title="Linkedin"><i className="fab fa-linkedin"></i></a></li>
                                                    <li><a href="/" title="Instagram"><i className="fab fa-instagram"></i></a></li>
                                                    
                                                </ul>
                                            </div>
                                            <label className="float-end mb-0"><a className="text-decoration" href="product-details.html"><small>View Details</small></a></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    );
};
export default QuickView;