import { loadJS } from "./Helpers/GlobalFunctions";
import { useEffect, useState, useRef } from "react";
import { useCartContext } from "./context/cart_context";
import FormatPrice from "./Helpers/FormatPrice";
import CartItemForCheckout from "./components/CartItemForCheckout";
import {useNavigate } from "react-router-dom";



//import axios from "axios";

const Checkout = () =>{

    const { cart, checkout, total_price, shipping_fee, addCheckoutData, placeOrder, sessionId, confirmOrder, current_user } = useCartContext();    
    const ref = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {  
        loadJS("./js/plugins.js", false);
        loadJS("./js/main.js", false);
        
    });
    useEffect(() => {
        window.scrollTo(0, 0);
        //delhiveryServiceability('110085');
    }, []);
    useEffect(() =>  {
        if(ref.current )
        {
            setTimeout(async () =>  {
                await ref.current.click();  
            }, 10);
        }
    }, [ref]);

    const [checkoutInput, setCheckoutInput] = useState({
        fullname: checkout.fullname,
        phone: checkout.phone,
        email: checkout.email,
        address: checkout.address,
        city: checkout.city,
        state: checkout.state,
        zipcode: checkout.zipcode,
        message: checkout.message,
    });
    const fullnameRef = useRef(null);
    const phoneRef = useRef(null);
    const emailRef = useRef(null);
    const addressRef = useRef(null);
    const cityRef = useRef(null);
    const stateRef = useRef(null);
    const zipcodeRef = useRef(null);

    const [payment_method, setPaymentMethod] = useState('razorpay');
    const [error, setError] = useState([]);
    const [load, setLoad] = useState(false);

    const handleInput = (e) => {
        e.persist();
        setCheckoutInput({...checkoutInput, [e.target.name]: e.target.value });
    }


    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() 
    { 
        if(payment_method===''){
            alert("Please select payment method.");
            return false;
        }

        if(!checkoutInput.fullname || checkoutInput.fullname==='')
        {
            alert("Please enter fullname.");
            fullnameRef.current.focus();
            return false;
        }
        if(!checkoutInput.address || checkoutInput.address==='')
        {
            alert("Please enter address.");
            addressRef.current.focus();
            return false;
        }
        if(!checkoutInput.zipcode || checkoutInput.zipcode==='')
        {
            alert("Please enter zipcode.");
            zipcodeRef.current.focus();
            return false;
        }
        if(!checkoutInput.city || checkoutInput.city==='')
        {
            alert("Please enter city.");
            cityRef.current.focus();
            return false;
        }
        if(!checkoutInput.state || checkoutInput.state==='')
        {
            alert("Please enter state.");
            stateRef.current.focus();
            return false;
        }
        if(!checkoutInput.email || checkoutInput.email==='')
        {
            alert("Please enter email address.");
            emailRef.current.focus();
            return false;
        }
        else
        {
            let validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

            if (!validEmail.test(checkoutInput.email) ) 
            {
                alert("Please enter valid email address.");
                emailRef.current.focus();
                return false;
            }
        }

        if(!checkoutInput.phone || checkoutInput.phone==='')
        {
            alert("Please enter phone number.");
            phoneRef.current.focus();
            return false;
        }
        else if( !(checkoutInput.phone.match('[0-9]{10}')) )
        {
            alert("Please enter 10 digit phone number.");
            phoneRef.current.focus();
            return false;
        }
        if(!cart || cart.length<=0)
        {
            alert("Please add any product in your cart.");
            return false;
        }
        
        
        addCheckoutData(checkoutInput);

        const orderData = {
            cart: cart,
            checkout: checkoutInput,
            sessionId: sessionId,
            user_id: current_user.userid,
            total_price: total_price,
            shipping_fee: shipping_fee,
        };
        //console.log("before:"+payment_id);
        const payment_id = await placeOrder(orderData);
        console.log("after place order:--"+payment_id+'--');
        //const result = await axios.get("https://shop.rxkin.com/apis/place_order.php");

        if (!payment_id) {
            alert("Server error. Are you online?");
            return;
        }
        else{
            if(payment_method==='razorpay'){
                loadRazorpay(payment_id);
            }
            else
            { 
                const responseData = {
                    orderCreationId: payment_id.trim(),
                    payment_method: payment_method,
                };
                const p_id = confirmOrder(responseData);
                console.log(p_id);
                if(p_id)
                {
                    navigate('/thankyou?payment_id='+p_id);
                }
            }
        }
    }

    function loadRazorpay(payment_id)
    {
        if(payment_method==='razorpay')
        {
            setTimeout(async () =>  {
                

                const res = await loadScript(
                    "https://checkout.razorpay.com/v1/checkout.js"
                );
                if (!res) {
                    alert("Razorpay SDK failed to load. Are you online?");
                    setError("Razorpay SDK failed to load. Are you online?");
                    return;
                }
                
                

                const amount = shipping_fee + total_price * 100;
                const currency = 'INR';

                const options = {
                    key: "rzp_live_T9niCSG03lJ9PR", // Enter the Key ID generated from the Dashboard
                    //key: "rzp_test_bPO0EkRY7Lx04i", // Enter the Key ID generated from the Dashboard
                    amount: amount.toString(),
                    currency: currency,
                    name: "RXKIN",
                    description: "",
                    image: "/images/logo.png",
                    order_id: payment_id.trim(),
                    handler: async function (response) {
                        console.log(response);
                        const responseData = {
                            orderCreationId: payment_id.trim(),
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpayOrderId: response.razorpay_order_id,
                            razorpaySignature: response.razorpay_signature,
                            payment_method: payment_method,
                        };
                        console.log(responseData);
                        //const result = await axios.post("http://localhost:5000/payment/success", data);

                        //alert(result.data.msg);
                        
                        //const result = await axios.get("https://shop.rxkin.com/apis/place_order.php");

                        if (!response) {
                            alert("Server error. Are you online?");
                            setError("Server error. Are you online?");
                            return;
                        }
                        console.log("after card use:"+payment_id);
                        if(responseData.razorpayPaymentId!=='')
                        {
                            const payment_id = confirmOrder(responseData);
                            //console.log(result);
                            if(payment_id)
                            {
                                navigate('/thankyou?payment_id='+payment_id);
                            }
                        }
                    },
                    prefill: {
                        name: checkoutInput.fullname,
                        email: checkoutInput.email,
                        contact: checkoutInput.phone,
                    },
                    notes: {
                        address: checkoutInput.address,
                    },
                    theme: {
                        color: "#61dafb",
                    },
                };
                console.log("options:"+JSON.toString(options));
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            }, 10);
        }
        
        
    }



    useEffect(() => {
        // Creating a new function named fetchCityState. 
        // We could have this outside the useEffect but this 
        // makes it more readable.
        const fetchCityState = async () => {
        // We are using a try/catch block inside an async function
        // which handles all the promises
        try {
            // Send a fetch request to the getCityState serverless function
            const response = await fetch(
            `https://api.postalpincode.in/pincode/${checkoutInput.zipcode}`,
            { headers: { accept: "application/json" } }
            );
            // We assign data to the response we receive from the fetch
            const data = await response.text();
            const ArrayData = JSON.parse(data);
            //console.log("zipcode MESSAGE:",ArrayData[0].Status)
            if(ArrayData[0].Status==='Success')
            {
                const pincodeData = ArrayData[0].PostOffice[0];
            setCheckoutInput({...checkoutInput, 
                city: pincodeData.Region, 
                state: pincodeData.State,
            });
             
            }
            

            //console.log("zipcode data:",ArrayData[0].PostOffice[0])
            // Using a spread operator is an easy way to populate our city/state
            // form
            //setCityState({...cityState, city: data, state: "" )
            // The catch(e) will console.error any errors we receive
        } catch (e) {
            console.log(e);
        }
        };
        // Run the above function
        fetchCityState();
        //The optional array below will run any time the zipcode
        // field is updated
    }, [checkoutInput.zipcode]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
        <div className="ltn__checkout-area mb-120 text-left">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 pl-0 pr-0">
                        <div className="ltn__checkout-inner">
                            <div className="ltn__checkout-single-content ltn__returning-customer-wrap">
                                <h5>Returning customer? <a className="ltn__secondary-color" href="#ltn__returning-customer-login" data-bs-toggle="collapse">Click here to login</a></h5>
                                <div id="ltn__returning-customer-login" className="collapse ltn__checkout-single-content-info">
                                    <div className="ltn_coupon-code-form ltn__form-box">
                                        <p>Please login your accont.</p>
                                        <form action="#" >
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-item input-item-name ltn__custom-icon">
                                                        <input type="text" name="ltn__name" placeholder="Enter your name"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-item-email ltn__custom-icon">
                                                        <input type="email" name="ltn__email" placeholder="Enter email address"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn theme-btn-1 btn-effect-1 text-uppercase">Login</button>
                                            <label className="input-info-save mb-0"><input type="checkbox" name="agree"/> Remember me</label>
                                            <p className="mt-30"><a href="register.html">Lost your password?</a></p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__checkout-single-content ltn__coupon-code-wrap hide">
                                <h5>Have a coupon? <a className="ltn__secondary-color" href="#ltn__coupon-code" data-bs-toggle="collapse">Click here to enter your code</a></h5>
                                <div id="ltn__coupon-code" className="collapse ltn__checkout-single-content-info">
                                    <div className="ltn__coupon-code-form">
                                        <p>If you have a coupon code, please apply it below.</p>
                                        <form action="#" >
                                            <input type="text" name="coupon-code" placeholder="Coupon code"/>
                                            <button className="btn theme-btn-2 btn-effect-2 text-uppercase">Apply Coupon</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__checkout-single-content mt-20">
                                <h4 className="title-2">Billing Details</h4>
                                <div className="ltn__checkout-single-content-info">
                                    <form action="#" >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-item">
                                                    <input type="text" name="fullname"  onChange={handleInput} value={checkoutInput.fullname} ref={fullnameRef} placeholder="Full Name"/>
                                                    <small className="text-danger">{error.fullname}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-item">
                                                    <input type="text" name="address"  onChange={handleInput} value={checkoutInput.address} ref={addressRef} placeholder="Address (House no, Building, Street, Area)"/>
                                                    <small className="text-danger">{error.address}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-item">
                                                    <input type="text" name="zipcode"  onChange={handleInput} value={checkoutInput.zipcode} ref={zipcodeRef} placeholder="Zipcode"/>
                                                    <small className="text-danger">{error.zipcode}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-item">
                                                    <input type="text" name="city"  onChange={handleInput} value={checkoutInput.city} ref={cityRef} placeholder="City"/>
                                                    <small className="text-danger">{error.city}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">    
                                            <div className="col-md-6">
                                                <div className="input-item">
                                                    <input type="text" name="state"  onChange={handleInput} value={checkoutInput.state} ref={stateRef} placeholder="State"/>
                                                    <small className="text-danger">{error.state}</small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-item">
                                                    <input type="email" name="email"  onChange={handleInput} value={checkoutInput.email} ref={emailRef} placeholder="email address"/>
                                                    <small className="text-danger">{error.email}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">    
                                            <div className="col-md-6">
                                                <div className="input-item">
                                                    <input type="text" name="phone"  onChange={handleInput} value={checkoutInput.phone} ref={phoneRef} placeholder="phone number"/>
                                                    <small className="text-danger">{error.phone}</small>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <p><label className="input-info-save mb-0"><input type="checkbox" name="agree"/> Create an account?</label></p> */}
                                        <div className="input-item input-item-textarea ltn__custom-icon hide">
                                            <textarea name="message"  onChange={handleInput} value={checkoutInput.message} placeholder="Notes about your order, e.g. special notes for delivery."></textarea>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="shoping-cart-total mt-50">
                            <h4 className="title-2">Cart Totals</h4>
                            <table className="table">
                                <tbody>
                                {(Array.isArray(cart) && cart.length)
                                    ? cart.map((curElem) => {
                                        return <CartItemForCheckout key={curElem.id} {...curElem} />;
                                    })
                                : null}
                                    
                                    <tr>
                                        <td>Shipping and Handing</td>
                                        <td><FormatPrice price={shipping_fee} /></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Order Total</strong></td>
                                        <td><strong><FormatPrice price={shipping_fee + total_price} /></strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="ltn__checkout-payment-method mt-50">
                            <h4 className="title-2">Payment Method</h4>
                            <div id="checkout_accordion_1">

                                
                                <div className="card">
                                    <h5 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" ref={ref}  onClick={() => setPaymentMethod('razorpay')}  >
                                        Pay Online 
                                    </h5>
                                    <div id="faq-item-2-3" className="collapse show" data-bs-parent="#checkout_accordion_1">
                                    </div>
                                </div>
                                <div className="card">
                                    <h5 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" onClick={() => setPaymentMethod('cod')}  > 
                                        Cash on delivery 
                                    </h5>
                                    <div id="faq-item-2-2" className="collapse " data-bs-parent="#checkout_accordion_1">
                                    </div>
                                </div>                          

                                
                            </div>
                            <div className="ltn__payment-note mt-30 mb-30">
                                {/* <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p> */}
                            </div>
                            <button disabled={load} className="btn theme-btn-1 text-uppercase" onClick={() =>{setLoad(true); displayRazorpay().then(()=> setLoad(false));}} type="submit">{load?<img src="/images/loading.gif" alt="loading" />:""} Place order</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        </>
    );
}; 
export default Checkout;